.main-addressdate {
  padding: 20px 0 20px 0;
  width: 100%;
  min-height: calc(100vh - 90px - 150px);
  background-color: #f3f5f6;
}

.form-mian {
  width: 100%;
}

.addressdate-text {
  width: 50%;
  margin: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #1b98e3;
}

.form-container {
  width: 720px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto;
}

.first-box {
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 90px;
}

.form-mian .errorMessage {
  width: 80%;
  text-align: left;
  font-size: 18px;
  /* height: 0; */
  margin: auto;
  /* margin: -110px 0 110px; */
  /* font-weight: 600; */
  color: #de4038;
}

.inner-main .errorMessage {
  width: 80%;
  text-align: left;
  font-size: 18px;
  height: 0;
  margin: 0;
  line-height: 0px;
  font-weight: 600;
  color: #de4038;
}

.line-border {
  display: none;
}
.second-box {
  flex: 0 0 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-bottom {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.form-item {
  position: relative;
  width: 80%;
  height: 60px;
  margin: 10px auto 20px auto;
}

.form-style {
  position: absolute;
  width: 100%;
  height: 48px;
  padding: 5px 0 5px 28px;
  border: 1px solid #1b9de3;
  border-radius: 27px;
  background: none;
  outline: none;
  z-index: 1;
}

.error-style {
  border: 1px solid #de4038;
}

.address-form-lable {
  position: absolute;
  top: -10px;
  left: 22px;
  padding: 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  background-color: #f3f5f6;
  color: #1b98e3;
  z-index: 10;
}

.error-text {
  color: #de4038;
}

.form-bottom {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 60px;
  margin: 10px auto;
}

.form-select {
  flex: 0 0 35%;
  width: 35%;
  height: 100%;
}

.form-zip {
  flex: 0 0 60%;
  width: 60%;
  margin-left: auto;
  height: 100%;
}

.form-field {
  position: relative;
  width: 100%;
  height: 60px;
}

/* Date Picker CSS*/

.form-datepicker {
  position: relative;
  width: 80%;
  height: 60px;
  margin: 10px auto;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin: 10px;
  padding: 5px;
}

.react-datepicker-popper {
  width: 100%;
  z-index: 11;
}

.react-datepicker {
  width: 100%;
  border: 1px solid #1b98e3;
  border-radius: 40px;
  margin-top: -17px;
  background-color: #f3f5f6;
}

.react-datepicker__header {
  background: transparent;
  width: 90%;
  margin: 0 auto;
}

.react-datepicker__current-month {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #444444;
  padding: 10px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -19px;
  top: 14px;
}

.react-datepicker__navigation-icon--next::before {
  left: -19px;
  top: 14px;
}

.react-datepicker__day {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #3b3c3d;
  margin: 10px;
  padding: 5px;
}

.react-datepicker__week,
.react-datepicker__header {
  border-bottom: 0.91701px solid #eeeeee;
}

.react-datepicker__month .react-datepicker__week:last-child {
  border-bottom: none;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background: #1b98e3;
  color: #ffffff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__year-text--selected:hover {
  border-radius: 50%;
  background-color: #1d5d90;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.react-datepicker__day-name {
  margin: 5px;
  padding: 5px;
}
.react-datepicker__day {
  margin: 5px;
  padding: 5px;
}

.form-calender {
  position: absolute;
  top: 20px;
  right: 23px;
}

.react-datepicker__day--disabled,
.react-datepicker__day--outside-month {
  color: lightgray !important;
  pointer-events: none;
}

.select {
  appearance: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.form-checked {
  position: absolute;
  top: 20px;
  right: 23px;
}

.form-btn-next {
  width: 192px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  outline: none;
  border: none;
  margin-right: 40px;
  align-self: flex-end;
  cursor: pointer;
}
.form-btn-next:not(:disabled):hover {
  background: #2188c6;
}

.form-btn-next:not(:disabled):active {
  background: #1b98e3;
}
.form-btn-next:disabled {
  opacity: 0.35;
  cursor: auto;
}

@media (max-width: 1200px) {
  .react-datepicker__day-name {
    margin: 3px;
    padding: 5px;
  }
  .react-datepicker__day {
    margin: 3px;
    padding: 3px;
  }
}

@media (max-width: 860px) {
  .form-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .addressdate-text {
    font-size: 14px;
    line-height: 19px;
    width: 85%;
  }
  .first-box {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 10px;
  }
  .second-box {
    flex: 0 0 100%;
    width: 100%;
  }
  .main-addressdate {
    padding: 20px 0 20px 0;
    width: 100%;
    min-height: calc(100vh);
    background-color: #f3f5f6;
  }

  .line-border {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 0px;
    border: 1px solid #c5c5c5;
    margin-bottom: 15px;
  }

  .form-btn-next {
    width: 192px;
    height: 56px;
    background: #1b98e3;
    border-radius: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    outline: none;
    border: none;
    margin-right: 50px;
    margin-top: 260px;
    align-self: flex-end;
  }
}

@media (max-width: 540px) {
  .main-addressdate {
    min-height: calc(100vh - 90px - 64px);
  }

  .form-btn-next {
    width: 125px;
    height: 40px;
    background: #1b98e3;
    border-radius: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    outline: none;
    border: none;
    margin-right: 35px;
    margin-top: 270px;
    align-self: flex-end;
  }
}
