/*
  Index.css:
  - Description: This is the formatting file of index.js component
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}