.main-password-changed {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.horizontal-line {
  margin: auto;
  width: 100%;
  max-width: 1042px;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-color: rgba(137, 221, 227, 0.6);
}

.password-changed-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1000px;
  height: 564px;
  margin: auto;
}

.password-changed-image {
  max-width: 29vw;
  width: 50%;
  margin: auto;
}

.password-changed-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.password-changed-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  margin-bottom: 40px;
}

.password-changed-form h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  max-width: 506px;
  margin-bottom: 86px;
  color: #3b3c3d;
}

.password-changed-verify-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  font-family: "Open Sans";
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.96px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 0 18px 0;
}

.password-changed-verify-btn:not(:disabled):hover {
  background: #2188c6;
}

.password-changed-verify-btn:not(:disabled):active {
  background: #1b98e3;
}

@media (max-width: 956px) {
  .main-password-changed {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .password-changed-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .password-changed-image {
    width: 50%;
    max-width: none;
    margin: 0 25%;
  }

  .password-changed-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 540px) {
  .main-password-changed {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .password-changed-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .password-changed-form h1 {
    font-size: 30px;
    margin: 0 0 12px 0;
  }

  .password-changed-image {
    width: 60%;
    margin: 0 20%;
  }

  .password-changed-form h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    margin: 0 0 32px;
    padding: 0 7%;
  }

  .password-changed-verify-btn {
    width: 73%;
    margin: 0 15% 24px 15%;
    font-size: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
}
