.landing-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 97px;
  width: 100%;
  background: #ffffff;
}

.header-shadow {
  box-shadow: 0 31px 31px 5px white;
}

.landing-logo {
  position: absolute;
  width: 139px;
  height: 69px;
  left: calc(50% - 139px / 2 + 0.5px);
  top: calc(50% - 69px / 2 + 7px);
  z-index: 5;
  cursor: pointer;
}
.custom-landing-logo {
  position: absolute;
  width: 139px;
  height: 69px;
  left: 87px;
  top: calc(50% - 69px / 2 + 7px);
  z-index: 5;
  cursor: pointer;
}
.login-btn {
  position: absolute;
  right: 40px;
  top: 20px;
  font-family: "Open Sans";
  width: 192px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #1b98e3;
  border: 1px solid #1b98e3;
  border-radius: 30px;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 10;
  font-family: "Open Sans";
}

.custom-login-btn {
  position: absolute;
  right: 329px;
  top: 20px;
  width: 192px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #1b98e3;
  border: 1px solid #1b98e3;
  border-radius: 30px;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 10;
  font-family: "Open Sans";
}

.qualify-btn {
  position: absolute;
  right: 40px;
  top: 20px;
  width: 265px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  border: 1px solid #1b98e3;
  border-radius: 30px;
  background-color: #1b98e3;
  cursor: pointer;
  z-index: 10;
  font-family: "Open Sans";
}

.qualify-btn:hover {
  background: #2188c6;
}

@media screen and (max-width: 1440px) {
  .landing-header {
    height: 97px;
  }
  .landing-logo {
    position: absolute;
    width: 139px;
    height: 69px;
    left: 80px;
    top: 14px;
  }
  .custom-landing-logo {
    position: absolute;
    width: 139px;
    height: 69px;
    left: 80px;
    top: 14px;
  }
  .login-btn {
    right: 32px;
    top: 27px;
    width: 144px;
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .custom-login-btn {
    right: 253px;
    top: 27px;
    width: 144px;
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .qualify-btn {
    right: 32px;
    top: 27px;
    width: 198px;
    height: 42px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 768px) {
  .landing-header {
    height: 56px;
  }
  .landing-logo {
    position: absolute;
    width: 65px;
    height: 32px;
    left: 24px;
    top: 12px;
  }
  .custom-landing-logo {
    position: absolute;
    width: 65px;
    height: 32px;
    left: 24px;
    top: 12px;
  }
  .login-btn {
    right: 16px;
    top: 12px;
    width: 105px;
    height: 33px;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }
  .custom-login-btn {
    right: 180px;
    top: 12px;
    width: 105px;
    height: 33px;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }

  .qualify-btn {
    right: 16px;
    top: 12px;
    width: 155px;
    height: 33px;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }
}

@media screen and (max-width: 400px) {
  .custom-login-btn {
    right: 150px;
    top: 12px;
    width: 80px;
    height: 33px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }

  .qualify-btn {
    right: 11px;
    top: 12px;
    width: 130px;
    height: 33px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }
}
