.main-page-response {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.horizontal-line {
  margin: auto;
  width: 100%;
  max-width: 1042px;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-color: rgba(137, 221, 227, 0.6);
}

.page-response-container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  height: 564px;
  margin: auto;
  max-width: 1042px;
}

#left-item {
  display: flex;
  justify-content: center;
  flex-basis: 48.7%;
  margin: auto;
}

.qual-failed {
  display: flex;
  justify-content: center;
  flex-basis: 35%;
  margin: auto;
}

.page-response-img {
  max-width: min(29vw, 470px);
}

.page-response-info {
  flex-basis: 51.3%;
  margin: auto;
  margin-left: 0px;
}

.page-response-info h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}

.qualified h1 {
  margin-top: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}

.page-response-info h3 {
  max-width: 506px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 28px 0 26px 0;
  color: #3b3c3d;
}

.qualified .page-response-btn {
  margin-top: 52px;
}

.page-response-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.96px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 0 18px 0;
}

.page-response-btn:hover {
  background: #2188c6;
}

.page-response-btn:active {
  background: #1b98e3;
}

@media (max-width: 956px) {
  .main-page-response {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .page-response-container {
    display: inline-block;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .page-response-img {
    width: 80%;
    height: 100%;
    max-width: none;
    margin: 5% 25%;
  }

  .page-response-info {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 540px) {
  .main-page-response {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .page-response-info h1 {
    font-size: 30px;
    margin: 0 0 8px 0;
  }

  .page-response-info h3 {
    width: 86%;
    font-size: 16px;
    text-align: center;
    line-height: 21.79px;
    margin: 0 0 32px 0;
    padding: 0;
  }

  .page-response-btn,
  .qualified .page-response-btn {
    width: 73%;
    margin: 0 15% 24px 15%;
    font-size: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
}
