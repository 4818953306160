.address-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-inner-data {
  margin-top: 24px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  color: #3b3c3d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-inner-description {
  margin: auto;
  width: 480px;
}

.address-popup-inner {
  position: relative;
  padding: 50px 40px;
  background: #ffffff;
  align-items: center;
  border-radius: 40px;
  height: 580px;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 24px;
  width: 24px;
}

.address-inner-icon {
  width: 56px;
  height: 56px;
}

.address-link-btn {
  display: block;
  width: 427px;
  height: 53px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 50px;
  color: #ffffff;
  margin-top: 38px;
  text-align: center;
  cursor: pointer;
}

.address-link-btn:hover {
  background: #2188c6;
}

.address-popup-table {
  text-align: center;
}

.address-popup-table th {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #c5c5c5;
}

.address-popup-table td {
  text-align: left;
  max-width: 400px;
}

.address-boarder {
  background-color: rgba(197, 197, 197, 0.8);
  width: 3px;
  min-height: 120px;
  border-radius: 10px;
}

.filled-address {
  padding-right: 20px;
}

.suggested-address {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .address-inner-description {
    margin: auto;
    width: 260px;
  }

  .address-popup-inner {
    height: 440px;
    padding: 30px;
  }

  .address-inner-data {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
  }

  .close-btn {
    height: 20px;
    width: 20px;
  }

  .address-inner-icon {
    width: 33px;
    height: 33px;
  }

  .address-link-btn {
    width: 273px;
    height: 40px;
    font-size: 16px;
    line-height: 38px;
    margin-top: 12px;
  }

  .steps {
    position: relative;
    margin-left: 15px;
    max-width: 274px;
    padding-right: 15px;
    font-size: 12px;
  }

  .address-popup-table th {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #c5c5c5;
  }

  .address-popup-table td {
    text-align: left;
    max-width: 200px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

@media (max-width: 540px) {
  .address-inner-description {
    margin: auto;
    width: 260px;
  }

  .address-popup-inner {
    height: 440px;
    padding: 30px;
    margin: 0 10px;
  }

  .address-inner-data {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
  }

  .close-btn {
    height: 20px;
    width: 20px;
  }

  .address-inner-icon {
    width: 33px;
    height: 33px;
  }

  .address-link-btn {
    width: 273px;
    height: 40px;
    font-size: 16px;
    line-height: 38px;
    margin-top: 12px;
  }

  .steps {
    position: relative;
    margin-left: 15px;
    max-width: 274px;
    padding-right: 15px;
    font-size: 12px;
  }

  .address-popup-table th {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #C5C5C5;
  }

  .address-popup-table td {
    text-align: left;
    max-width: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 19px;
  }
}
