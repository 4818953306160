.main-reviewinfo {
  padding: 20px 0 75px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.inner-main {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.errorMessage-new {
  width: 365px;
  height: 50px;
  margin-top: -15px;
  font-size: 18px;
  line-height: 24.51px;
  text-align: left;
  font-weight: normal;
  color: #de4038;
  font-family: "Open Sans";
}

.inner-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 50px;
  content: "";
}

.inner-title::before {
  content: "Please review";
  cursor: text;
}

.inner-title-2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 55px;
  text-align: center;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  content: "";
}

.inner-title-2::before {
  content: "your information";
  cursor: text;
}

.border-bottom-line {
  width: 443px;
  border: 1px solid #34b2ea;
  margin: 30px auto;
  text-align: center;
}

.review-main-box {
  width: 435px;
  height: 250px;
  margin: 30px auto;
  text-align: center;
}

.review-row {
  display: flex;
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
  border: 0;
}

.review-col-l {
  flex: 1;
  width: 50%;
  flex-basis: 50%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  color: #3b3c3d;
}
.review-col-r {
  flex: 1;
  width: 50%;
  flex-basis: 50%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  color: #3b3c3d;
}

.review-col-r-date {
  flex: 1;
  width: 50%;
  flex-basis: 50%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  color: #3b3c3d;
}
.review-col-r-date-failed {
  flex: 1;
  width: 50%;
  flex-basis: 50%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  color: #de4038;
}
.review-col-r-new {
  flex: 1;
  width: 50%;
  flex-basis: 50%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  text-align: right;
  color: #3b3c3d;
}

.review-row-button {
  display: flex;
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
  border: 0;
}

.review-btn {
  width: 192px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  outline: none;
  border: none;
  margin: 30px 50px;
}

.review-btn:hover {
  background: #2188c6;
}

.review-btn:active {
  background: #1b98e3;
}

.review-btn:disabled {
  opacity: 0.35;
}

@media (max-width: 768px) {
  .errorMessage-new {
    width: 226px;
    text-align: left;
    font-size: 11px;
    height: 30px;
    margin: 0;
    margin-top: -22px;
    line-height: 15px;
    font-weight: normal;
    color: #de4038;
  }

  .main-reviewinfo {
    min-height: calc(100vh - 56px - 150px);
    padding: 20px 0 54px 0;
  }

  .inner-title {
    font-size: 30px;
    line-height: 41px;
    margin-top: 24px;
    content: "";
  }

  .inner-title::before {
    content: "Please review your";
  }

  .inner-title-2 {
    font-size: 30px;
    line-height: 41px;
    content: "information";
  }

  .inner-title-2::before {
    content: "information";
  }

  .border-bottom-line {
    width: 300px;
    border: 1px solid #34b2ea;
    margin: 12px auto;
    text-align: center;
  }

  .review-main-box {
    width: 155px;
    height: 212px;
    margin: 2px auto 30px auto;
  }

  .review-row {
    flex-direction: column;
  }

  .review-col-l {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .review-col-r {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .review-col-r-date {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .review-col-r-date-failed {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .review-col-r-new {
    flex: 1;
    width: 50%;
    flex-basis: 50%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #3b3c3d;
  }
  .review-row-button {
    max-width: 305px;
  }
  .review-btn {
    width: 125px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0;
  }
}
