.terms-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-popup-inner {
  position: relative;
  padding: 40px;
  width: 100%;
  max-width: calc(100vw - 310px - 310px);
  height: calc(100vh - 72px - 72px);
  background: #ffffff;
  align-items: center;
  border-radius: 40px;
}

.popup-title {
  margin: 0 auto;
  align-items: center;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #1b98e3;
}

.terms-close-btn {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  height: 24px;
  width: 24px;
}

.terms-inner-data {
  width: calc(100vw - 360px - 360px);
  height: calc(100vh - 72px - 72px - 123px - 57px);
  margin: 0 auto;
  background-color: #f3f5f6;
  margin-top: 28px;
  overflow-y: scroll;
}
.terms-inner-data::-webkit-scrollbar {
  -webkit-appearance: none;
}
.terms-inner-data::-webkit-scrollbar:vertical {
  width: 11px;
}
.terms-inner-data::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #f3f5f6;
  background-color: #c4c4c4;
}

.terms-inner-data-text {
  width: calc(100vw - 410px - 410px);
  margin: 0 auto;
  margin-top: 2px;
}

.sub-1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #3b3c3d;
}

.sub-2 {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 22px;
  color: #3b3c3d;
  margin-top: 2px;
  margin-bottom: 30px;
}

.sub-3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #3b3c3d;
}

ul {
  font-family: "Open Sans";
  font-style: normal;
  color: #3b3c3d;
}

li {
  margin-top: 10px;
}

@media (max-width: 1400px) {
  .terms-popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: calc(100vw - 200px - 200px);
    height: calc(100vh - 72px - 72px);
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }
  .terms-inner-data {
    width: calc(100vw - 250px - 250px);
    height: calc(100vh - 72px - 72px - 123px - 57px);
    margin: 0 auto;
    background-color: #f3f5f6;
    margin-top: 28px;
    overflow-y: scroll;
  }

  .terms-inner-data-text {
    width: calc(100vw - 300px - 300px);
    margin: 0 auto;
    margin-top: 0px;
  }
}

@media (max-width: 1200px) {
  .terms-popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: calc(100vw - 100px - 100px);
    height: calc(100vh - 72px - 72px);
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }
  .terms-inner-data {
    width: calc(100vw - 150px - 150px);
    height: calc(100vh - 72px - 72px - 123px - 57px);
    margin: 0 auto;
    background-color: #f3f5f6;
    margin-top: 28px;
    overflow-y: scroll;
  }

  .terms-inner-data-text {
    width: calc(100vw - 200px - 200px);
    margin: 0 auto;
    margin-top: 0px;
  }
}

@media (max-width: 940px) {
  .terms-popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: calc(100vw - 75px - 75px);
    height: calc(100vh - 72px - 72px);
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }
  .terms-inner-data {
    width: calc(100vw - 125px - 125px);
    height: calc(100vh - 72px - 72px - 123px - 57px);
    margin: 0 auto;
    background-color: #f3f5f6;
    margin-top: 28px;
    overflow-y: scroll;
  }

  .terms-inner-data-text {
    width: calc(100vw - 175px - 175px);
    margin: 0 auto;
    margin-top: 0px;
  }

  .sub-1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    color: #3b3c3d;
  }

  .sub-2 {
    font-family: "Open Sans";
    font-style: normal;
    font-size: 16px;
    color: #3b3c3d;
    margin-top: 2px;
    margin-bottom: 30px;
  }

  .sub-3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #3b3c3d;
  }
  li {
    font-size: 16px;
  }
  .popup-title {
    margin: 0 auto;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 46px;
    color: #1b98e3;
  }
}
@media (max-width: 768px) {
  .terms-popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: calc(100vw - 50px - 50px);
    height: calc(100vh - 72px - 72px);
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }
  .terms-inner-data {
    width: calc(100vw - 100px - 100px);
    height: calc(100vh - 72px - 72px - 123px - 57px);
    margin: 0 auto;
    background-color: #f3f5f6;
    margin-top: 28px;
    overflow-y: scroll;
  }

  .terms-inner-data-text {
    width: calc(100vw - 150px - 150px);
    margin: 0 auto;
    margin-top: 0px;
  }

  .popup-title {
    margin: 0 auto;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 46px;
    color: #1b98e3;
  }

  .sub-1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    color: #3b3c3d;
  }

  .sub-2 {
    font-family: "Open Sans";
    font-style: normal;
    font-size: 16px;
    color: #3b3c3d;
    margin-top: 2px;
    margin-bottom: 30px;
  }

  .sub-3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #3b3c3d;
  }
  li {
    font-size: 16px;
  }
}

@media (max-width: 540px) {
  .terms-popup-inner {
    position: relative;
    padding: 20px;
    width: 100%;
    max-width: calc(100vw - 10px - 10px);
    height: calc(100vh - 72px - 72px);
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }
  .terms-inner-data {
    width: calc(100vw - 40px - 40px);
    height: calc(100vh - 72px - 72px - 100px - 50px);
    margin: 0 auto;

    background-color: #f3f5f6;
    margin-top: 15px;
    overflow-y: scroll;
  }

  .terms-inner-data-text {
    width: calc(100vw - 50px - 80px);
    margin: 0 auto;
    margin-top: 10px;
  }

  .terms-close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 20px;
  }

  .popup-title {
    margin: 0 auto;
    align-items: center;
    text-align: center;
    margin-top: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 46px;
    color: #1b98e3;
  }

  .sub-1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 30px;
    color: #3b3c3d;
  }

  .sub-2 {
    font-family: "Open Sans";
    font-style: normal;
    font-size: 14px;
    color: #3b3c3d;
    margin-top: 2px;
    margin-bottom: 30px;
  }

  .sub-3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #3b3c3d;
  }
  li {
    font-size: 14px;
  }
}
