@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.flow-table {
  margin: auto;
  width: 600px;
  padding: 0px;
}

.flow-table-circle {
  padding: 0px;
  margin-left: -5px;
  margin-right: -5px;
}

.flow-table-bar {
  padding: 0px;
  width: 100px;
  margin-left: -5px;
  margin-right: -5px;
}

.circle {
  padding: 10px;
  border-radius: 100%;
  background-color: black;
  width: 100px;
  height: 100px;
}

.circle-todo {
  border-radius: 100%;
  background-color: #c5c5c5;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.circle-current {
  border-radius: 100%;
  background-color: #1b98e3;
  color: #ffffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.flow-circle-done {
  background-image: url("/src/assets/icon_success.png");
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: #3fc1c0;
  width: 40px;
  height: 40px;
}

.flow-table-td {
  position: relative;
}

.text-todo {
  color: #c5c5c5;
  width: 40px;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.text-current {
  color: #1b98e3;
  width: 40px;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.text-done {
  color: #3fc1c0;
  width: 40px;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.bar-todo {
  background-color: #c5c5c5;
  height: 4px;
}

.bar-done {
  background-color: #3fc1c0;
  height: 4px;
}

.container {
  height: 50px;
  margin: auto;
  width: 700px;
  padding: 0px;
}

.flow-box {
  position: relative;
  width: 0px;
  height: 40px;
  margin: 0 10%;
  white-space: nowrap;
  float: left;
}

.box-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.box-text-todo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c5c5c5;
}

.box-text-current {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1b98e3;
}

.box-text-done {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #3fc1c0;
}

@media (max-width: 768px) {
  .flow-table {
    width: 78.125%;
  }

  .flow-table-circle{
    width: 5.2vw;
    min-width: 30px;
  }

  .circle-current, .flow-circle-done, .circle-todo {
    width: 100%;
    height: 5.2vw;
    min-height: 30px;
    background-size: 55%;
    font-size: 20px;
    line-height: 180%;
  }

  .flow-table-bar {
    width: calc(100% / 6);
  }

  .box-text-current, .box-text-done, .box-text-todo {
    font-size: 12px;
  }

  .container {
    width: 92%;
  }
}

@media (max-width: 499px) {
  .circle-current, .flow-circle-done, .circle-todo {
    font-size: 1em;
    line-height: 2em;
  }

  .container {
    width: calc(100% - 40px);
  }
}