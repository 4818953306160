.main-confirmationcode {
  padding: 20px 0 20px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 86px);
  background-color: #f3f5f6;
}

.confirmationcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  margin: 3% auto 10%;
}

.confirmationcode-image {
  max-width: 30vw;
  width: 42%;
  margin: 0 50px 0 0;
}

.confirmationcode-form {
  width: 58%;
  margin: 0;
}

.confirmationcode-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.confirmationcode-form h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  max-width: 500px;
  color: #3b3c3d;
}

.confirmationcode-code {
  width: 462px;
  margin: 0 0 0 -10px;
}

.confirmationcode-code input {
  width: 57px;
  height: 71px;
  text-align: center;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 11px;
  margin: 0 10px;
  font-size: 36px;
  font-weight: 600;
  color: #3b3c3d;
}

.confirmationcode-code p {
  color: #de4038;
  margin: 0 0 0 10px;
  height: 0px;
  font-size: 18px;
}

.error-message input {
  border: 1px solid #de4038;
}

.confirmationcode-buttons {
  width: 427px;
}

.confirmationcode-verify-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.96px;
  color: #ffffff;
  cursor: pointer;
  margin: 57px 0 18px 0;
}

.confirmationcode-verify-btn:not(:disabled):hover {
  background: #2188c6;
}

.confirmationcode-verify-btn:not(:disabled):active {
  background: #1b98e3;
}

.confirmationcode-verify-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.confirmationcode-resend-btn {
  background: none;
  border: none;
  padding: 0;
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  line-height: 29.96px;
  text-align: center;
  color: #1b98e3;
  cursor: pointer;
  width: 188px;
  display: block;
  margin-left: auto;
  margin-right: 118px;
}

@media (max-width: 956px) {
  .confirmationcode-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .main-confirmationcode {
    min-height: calc(100vh - 90px - 64px);
  }

  .confirmationcode-container {
    display: inline-block;
    width: 100%;
    margin-bottom: auto;
  }

  .confirmationcode-image {
    width: 50%;
    max-width: none;
    margin: -10% 25% 0%;
  }

  .confirmationcode-form {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .confirmationcode-form h1 {
    font-size: 30px;
    margin: 0;
  }

  .confirmationcode-form h3 {
    font-size: 18px;
    text-align: center;
    line-height: 21.79px;
    margin: 0 0 12px;
  }

  .confirmationcode-code {
    width: auto;
    margin: 0 auto;
  }

  .confirmationcode-code input {
    width: 42px;
    height: 52px;
    margin: 0 4px;
    font-size: 30px;
    padding: 0;
  }

  .confirmationcode-buttons {
    width: 100%;
  }

  .confirmationcode-verify-btn {
    width: 73%;
    height: 40px;
    font-size: 16px;
    margin: 10% 13.5% 5%;
  }

  .confirmationcode-resend-btn {
    font-size: 16px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .confirmationcode-code p {
    font-size: 12px;
  }

  .confirmationcode-footer .page-footer {
    background-color: transparent;
  }
}
