.qualified-header {
  max-width: 560px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #3b3c3d;
  margin: 0;
}

.qualified-body {
  max-width: 560px;
  font-family: "Open Sans";
  font-style: 400;
  font-size: 22px;
  line-height: 30px;
  color: #3b3c3d;
  margin: 0;
}
.qualified-list {
  margin-top: 0px;
}
@media (max-width: 956px) {
  .qualified-container {
    width: 80%;
  }

  .qualified-header {
    max-width: none;
    text-align: left !important;
  }

  .qualified-body {
    max-width: none;
    text-align: left !important;
  }
}

@media (max-width: 540px) {
  .qualified-container {
    width: 80%;
  }

  .qualified-header {
    max-width: none;
    font-size: 16px;
    line-height: 22px;
    text-align: left !important;
    margin-top: 15px;
  }

  .qualified-body {
    max-width: none;
    font-size: 14px;
    line-height: 19px;
    text-align: left !important;
  }
}
