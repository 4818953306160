.confirm-page-container {
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
}

.confirm-page-row {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-self: center;
  margin: 20px auto;
  padding: 110px 0;
  max-width: 1440px;
  width: 100%;
}

.confirm-page-col-1 {
  flex: 1;
  height: 446px;
  margin-top: 10px;
  padding-top: 13px;
  padding-left: 15%;
}

.confirm-page-col-2 {
  flex: 1;
  padding-right: 15%;
}

.confirm-page-des h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #3b3c3d;
  margin: 0;
}

.confirm-title-right {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 48px;
  margin-bottom: 40px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.confirm-dashboard-btn {
  display: block;
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 96px;
  font-family: "Open Sans";
}

.confirm-dashboard-btn:hover {
  background: #2188c6;
}

.confirm-dashboard-btn:active {
  background: #1b98e3;
}

@media (max-width: 1439px) {
  .confirm-page-row {
    max-width: 1000px;
  }

  .confirm-page-col-1 {
    min-width: 560px;
    max-width: 560px;
    padding: 0;
  }

  .confirm-page-col-2 {
    min-width: 560px;
    max-width: 560px;
    padding: 0;
    text-align: center;
  }

  .confirm-dashboard-btn {
    margin: 96px auto;
  }
}

@media (max-width: 768px) {
  .confirm-page-container {
    min-height: calc(100vh - 90px - 64px);
  }

  .confirm-page-row {
    max-width: 600px;
    padding: 0;
    margin: 24px auto;
  }

  .confirm-page-col-2 {
    min-width: 500px;
    max-width: 500px;
  }
}

@media (max-width: 540px) {
  .confirm-page-container {
    min-height: calc(100vh - 90px - 64px);
    overflow: hidden;
  }

  .confirm-page-row {
    max-width: 600px;
    padding: 0;
    margin: 24px auto;
  }

  .confirm-page-col-2 {
    min-width: 320px;
    max-width: 320px;
  }

  .confirm-title-right {
    font-size: 30px;
    line-height: 41px;
  }

  .confirm-page-des h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .confirm-dashboard-btn {
    width: 274px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 32px;
    margin-bottom: 40px;
  }
}

@media (max-width: 320px) {
  .confirm-page-col-2 {
    min-width: 280px;
    max-width: 280px;
  }

  .confirm-page-col-1 {
    height: 100%;
  }
}
