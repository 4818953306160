@media (min-height: 1200px) {
  .signup-container {
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    min-height: calc(100vh - 100px);
    height: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-self: center;
  }
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.signup-main {
  width: 100%;
  min-height: calc(100vh - 97px - 150px);
}
.pass-format {
  color: #3b3c3d;
  font-family: "Open Sans";
  font-size: 22px;
}

.signup-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 97px;
  height: 100%;
  width: 100%;
}

.signup-banner::before {
  content: "";
  position: absolute;
  background-image: url("/src/assets/signup-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.signup-container {
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-self: center;
}

.signup-col-1 {
  flex: 1;
  margin: 0 68px 0 124px;
  display: flex;
  flex-direction: column;
  min-width: 576px;
}

.signup-col-2 {
  flex: 1;
  margin: 0 96px 0 0;
  min-width: 576px;
  display: flex;
  flex-direction: column;
}

.signup-title {
  margin: 105px 0 0 0;
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #3e4954;
  padding-right: 48px;
}

.signup-description {
  margin: 23px 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #3e4954;
  padding-right: 48px;
}

.signup-Stepper {
  width: 100%;
  position: relative;
  margin-top: 46px;
}

.signup-stepper-list {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.signup-stepper-list-item {
  display: flex;
  height: 70px;
  align-items: center;
}

.signup-stepper-circle {
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  border-radius: 50%;
  background-color: #1b98e3;
  color: white;
}

.signup-stepper-circle::after {
  content: "";
  border: 2px solid #1b98e3;
  position: absolute;
  height: 40px;
  display: block;
  left: 18px;
}

.signup-stepper-list-item:last-child .signup-stepper-circle::after {
  display: none;
  letter-spacing: 0.05em;
  color: #3e4954;
  padding: 10px;
}

.signup-stepper-title {
  margin-left: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #3e4954;
}

.signup-box {
  width: 560px;
  min-width: 560px;
  margin: 80px 0 150px 0;
  background: linear-gradient(
    95.18deg,
    rgba(255, 255, 255, 0.9) 29.54%,
    rgba(255, 255, 255, 0.2) 181.08%
  );
  border: 0px solid rgba(255, 255, 255, 0.6);
  border-radius: 40px;
}

.signup-form {
  width: 76.25%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.signup-form-des {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #1b98e3;
  margin: 72px 0 40px 0;
  padding-right: 30%;
}

.signup-row {
  margin-bottom: 16px;
}

.input-box {
  height: 77px;
  margin-bottom: 16px;
}

.signup-inputbox {
  position: relative;
}

.signup-error-text,
.signup-error-text-server {
  align-items: center;
  text-align: left;
  margin: 0 0 0 32px;
  line-height: 22px;
  font-size: 14px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
}

.signup-error-text-server {
  margin: 30px 0 0 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  text-align: center;
}

.signup-inputfield-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background: transparent;
}

.signup-inputfield::-webkit-input-placeholder,
.signup-inputfield-failed::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
}

.signup-inputfield {
  font-family: "Open Sans";
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background: transparent;
}

.signup-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.signup-img {
  width: 88px;
}

.signup-input-validate {
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.input-validate {
  width: 74px;
}

.signup-btn {
  display: block;
  width: 100%;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 40px 0 99px 0;
  cursor: pointer;
}

.signup-btn:not(:disabled):hover {
  background: #2188c6;
}

.signup-btn:not(:disabled):active {
  background: #1b98e3;
}

.signup-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.signup-qualification {
  color: #de4038;
  font-weight: 600;
}

.MuiTooltip-tooltip {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  border-radius: 10px !important;
  padding: 19px 13px 19px 13px !important;
}

.signup-inputbox div span {
  left: -170px !important;
}

.MuiTooltip-arrow::before {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  margin-top: -1px !important;
}

.signup-condition-row {
  position: relative;
  width: 100% !important;
  vertical-align: center;
  height: 25px;
}
.signup-condition-img-holder {
  width: 13px;
  margin-top: auto;
  margin-right: 8px;
  top: 4.5px;
}
.signup-condition-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  left: 35px;
}

@media (max-width: 1439px) {
  .signup-container {
    max-width: 1000px;
  }

  .signup-col-1 {
    margin: 0 auto 200px auto;
    min-width: 560px;
    max-width: 560px;
    padding: 0 72px;
  }

  .signup-col-2 {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 720px;
  }

  .signup-box {
    margin: 0 auto 100px auto;
  }

  .signup-title {
    display: none;
  }

  .signup-description {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .pass-format {
    color: #3b3c3d;
    font-weight: unset;
    font-family: "Open Sans";
    font-size: unset;
  }
  .signup-main {
    min-height: calc(100vh - 56px - 150px);
  }

  .signup-banner {
    top: 56px;
  }

  .signup-banner::before {
    background-image: url("/src/assets/signup-img-mobile.png");
    background-position: center;
  }

  .signup-container {
    max-width: 600px;
  }

  .signup-col-1 {
    margin: 0 auto;
    min-width: 273px;
    max-width: 273px;
    padding: 0;
  }

  .signup-col-2 {
    margin: 40px auto auto auto;
    max-width: 329px;
    min-width: 329px;
  }

  .signup-title {
    display: none;
  }

  .signup-description {
    font-size: 16px;
    line-height: 22px;
    padding-right: 0;
  }

  .signup-Stepper {
    margin-top: 10px;
    margin-bottom: 120px;
  }

  .signup-stepper-list-item {
    height: 64px;
  }

  .signup-stepper-circle {
    line-height: 36px;
    text-align: center;
    width: 36px;
    height: 36px;
    font-size: 20px;
  }

  .signup-stepper-circle::after {
    height: 40px;
    left: 16px;
  }

  .signup-stepper-title {
    margin-left: 10.5px;
    font-size: 14px;
    line-height: 19px;
  }

  .signup-box {
    width: 329px;
    min-width: 329px;
    height: 600px;
    margin: 0;
  }

  .signup-form {
    width: 83.28%;
  }

  .signup-form-des {
    font-size: 22px;
    line-height: 30px;
    margin: 30px 0 24px 0;
    padding-right: 25.18%;
  }

  .signup-row {
    margin-bottom: 8px;
  }

  .input-box {
    height: 56px;
  }

  .signup-inputbox {
    position: relative;
  }

  .signup-error-text,
  .signup-error-text-server {
    align-items: center;
    text-align: left;
    margin: 0 0 0 0px;
    line-height: 14px;
    font-size: 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
  }

  .signup-error-text-server {
    margin: 15px 0 0 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
  }

  .signup-inputfield {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  .signup-inputfield-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  .signup-inputfield::-webkit-input-placeholder,
  .signup-inputfield-failed::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
  }

  .signup-img {
    width: 70px;
  }

  span.signup-img {
    width: 70px;
  }

  .signup-img #email1,
  .signup-img #email2 {
    width: 17.5px;
    height: 13.75px;
  }
  .signup-img #password1,
  .signup-img #password2 {
    width: 15.82px;
    height: 15.51px;
  }

  .signup-img #phonenumber1,
  .signup-img #phonenumber2 {
    width: 18.93px;
    height: 18.53px;
  }

  .signup-input-validate {
    width: 52px;
  }

  span.input-validate {
    width: 52px;
  }

  .signup-input-validate img {
    width: 20px;
    height: 20px;
  }

  .signup-btn {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin: 36px 0 70px 0;
    padding: 5px 0;
  }

  .signup-footer {
    margin-top: -8px;
  }

  .signup-inputbox div div {
    padding: 5px 13px 10px 3px !important;
  }

  .signup-inputbox div span {
    left: -102px !important;
  }

  .signup-condition-row {
    position: relative;
    width: 100% !important;
    vertical-align: center;
    height: 20px;
  }
  .signup-condition-img-holder {
    width: 10px;
    margin-top: auto;
    margin-left: 13px;
    top: 3.5px;
  }
  .signup-condition-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #ffffff;
    left: 30px;
  }
}

@media (max-width: 359px) {
  .signup-main {
    min-height: calc(100vh - 56px - 150px);
  }

  .signup-banner::before {
    background-image: url("/src/assets/signup-img-mobile.png");
    background-position: center;
  }

  .signup-container {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-self: center;
  }

  .signup-col-1 {
    margin: 0 auto;
    min-width: 200px;
    max-width: 200px;
    padding: 0;
  }

  .signup-col-2 {
    margin: 40px auto auto auto;
    max-width: 250px;
    min-width: 250px;
  }

  .signup-title {
    display: none;
  }

  .signup-description {
    margin: 23px 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3e4954;
    padding-right: auto;
  }

  .signup-Stepper {
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 70px;
  }

  .signup-stepper-list-item {
    display: flex;
    height: 64.3175px;
    align-items: center;
  }

  .signup-stepper-circle {
    line-height: 35.73px;
    text-align: center;
    width: 35.73px;
    height: 35.73px;
    font-size: 19.6524px;
    font-weight: 600;
    font-style: normal;
    border-radius: 50%;
    background-color: #1b98e3;
    color: white;
  }

  .signup-stepper-circle::after {
    content: "";
    border: 2px solid #1b98e3;
    position: absolute;
    height: 28.59px;
    display: block;
    left: 16.295px;
  }

  .signup-stepper-list-item:last-child .signup-stepper-circle::after {
    display: none;
    letter-spacing: 0.05em;
    color: #3e4954;
    padding: 10px;
  }

  .signup-box {
    width: 250px;
    min-width: 250px;
    margin: 0;
  }

  .signup-form {
    width: 83.28%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .signup-form-des {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #1b98e3;
    margin: 30px 0 24px 0;
    padding: 0;
  }

  .signup-row {
    margin-bottom: 8px;
  }

  .input-box {
    height: 56px;
  }

  .signup-inputbox {
    position: relative;
  }

  .signup-error-text,
  .signup-error-text-server {
    align-items: center;
    text-align: left;
    margin: 0 0 0 10px;
    font-size: 7px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
  }

  .signup-error-text-server {
    margin: 15px 0 0 0;
    font-size: 9px;
    line-height: 14px;
    font-weight: 400;
    text-align: center;
  }

  .signup-inputfield {
    font-size: 12px;
    height: 40px;
    text-indent: 40px;
    border-radius: 20px;
    border: 1px solid #1b98e3;
  }

  .signup-inputfield-failed {
    font-size: 12px;
    height: 40px;
    text-indent: 40px;
    border-radius: 20px;
    border: 1px solid #de4038;
  }

  .signup-inputfield::-webkit-input-placeholder,
  .signup-inputfield-failed::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
  }

  .signup-img {
    width: 48px;
  }

  span.signup-img {
    width: 48px;
  }

  .signup-img #email1,
  .signup-img #email2 {
    width: 17.5px;
    height: 13.75px;
  }
  .signup-img #password1,
  .signup-img #password2 {
    width: 15.82px;
    height: 15.51px;
  }

  .signup-img #phonenumber1,
  .signup-img #phonenumber2 {
    width: 18.93px;
    height: 18.53px;
  }

  .signup-input-validate {
    width: 52px;
  }

  span.input-validate {
    width: 52px;
  }

  .signup-input-validate img {
    width: 20px;
    height: 20px;
  }

  .signup-btn {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin: 36px 0 70px 0;
    padding: 5px 0;
  }

  .input-validate img {
    width: 20px;
    height: 20px;
  }

  .signup-inputbox div span {
    left: -80px !important;
  }

  .signup-condition-row {
    position: relative;
    width: 100% !important;
    vertical-align: center;
    height: 18px;
  }
  .signup-condition-img-holder {
    width: 10px;
    margin-top: auto;
    margin-left: 10px;
    top: 3.5px;
  }
  .signup-condition-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 18px;
    color: #ffffff;
    left: 25px;
  }

  .signup-inputbox div div {
    padding: 3px 7px 10px 1px !important;
  }
}
