.status-wrapper {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 50px;
  max-width: 1440px;
}

.box-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.line-container {
  width: 0;
}

.box {
  width: 289px;
  margin: 0 0 0 55px;
}

.box-inner-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #3b3c3d;
  margin-bottom: 12px;
}

.box-inner-details-still {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #b1b5ba;
}

.box-inner-details-done {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #3fc1c0;
}

.circle-still {
  background-image: url("/src/assets/icon_still.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(208, 211, 215, 0.5);
  width: 64px;
  height: 64px;
  margin-top: 6px;
}

.circle-progress {
  background-image: url("/src/assets/icon_progress.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(185, 220, 241, 0.5);
  width: 64px;
  height: 64px;
  margin-top: 6px;
}

.circle-done {
  background-image: url("/src/assets/icon_success_green.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(137, 221, 227, 0.5);
  width: 64px;
  height: 64px;
  margin-top: 6px;
}

.box-inner-button {
  font-family: "Open Sans";
  display: block;
  width: 148px;
  height: 47px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  padding: 0px;
  margin-top: 18px;
  cursor: pointer;
}

.box-inner-button:not(:disabled):hover {
  background: #2188c6;
}

.box-inner-button:not(:disabled):active {
  background: #1b98e3;
}
.box-inner-button:disabled {
  opacity: 0.35;
  cursor: auto;
}

/* Vertical bar*/
.status-wrapper-confirm {
  position: absolute;
  width: 483px;
  height: 446px;
}

.box-1-confirm {
  position: absolute;
  width: 289px;
  height: 131px;
  left: 143px;
  top: 0px;
}
.box-2-confirm {
  position: absolute;
  width: 289px;
  height: 131px;
  left: 143px;
  top: 163px;
}
.box-3-confirm {
  position: absolute;
  width: 289px;
  height: 131px;
  left: 143px;
  top: 328px;
}

.box-inner-title-confirm {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #3b3c3d;
}

.box-inner-details-done-confirm {
  position: absolute;
  top: 45px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #3fc1c0;
}

.box-inner-details-still-confirm {
  position: absolute;
  top: 45px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #b1b5ba;
}

.circle-done-1-confirm {
  position: absolute;
  left: 35px;
  top: 5px;
  background-image: url("/src/assets/icon_success_green.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(137, 221, 227, 0.5);
  width: 64px;
  height: 64px;
}

.vertical-line-1-confirm {
  position: absolute;
  left: 66px;
  top: 80px;
  width: 2px;
  height: 80px;
  background-color: rgba(137, 221, 227, 0.5);
}

.circle-done-2-confirm {
  position: absolute;
  left: 35px;
  top: 171px;
  background-image: url("/src/assets/icon_success_green.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(137, 221, 227, 0.5);
  width: 64px;
  height: 64px;
}

.circle-still-2-confirm {
  position: absolute;
  left: 35px;
  top: 171px;
  background-image: url("/src/assets/icon_still.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(208, 211, 215, 0.5);
  width: 64px;
  height: 64px;
}

.circle-progress-2-confirm {
  position: absolute;
  left: 35px;
  top: 171px;
  background-image: url("/src/assets/icon_progress.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(185, 220, 241, 0.5);
  width: 64px;
  height: 64px;
}

.box-inner-button-confirm {
  font-family: "Open Sans";
  display: block;
  width: 148px;
  height: 47px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 15px;
  cursor: pointer;
}

.box-inner-button-confirm:not(:disabled):hover {
  background: #2188c6;
}

.box-inner-button-confirm:not(:disabled):active {
  background: #1b98e3;
}
.box-inner-button-confirm:disabled {
  opacity: 0.35;
  cursor: auto;
}
.vertical-line-active-2-confirm {
  position: absolute;
  left: 66px;
  top: 246px;
  width: 2px;
  height: 80px;
  background-color: rgba(137, 221, 227, 0.5);
}

.vertical-line-inactive-2-confirm {
  position: absolute;
  left: 66px;
  top: 246px;
  width: 2px;
  height: 80px;
  background-color: rgba(208, 211, 215, 0.5);
}

.circle-done-3-confirm {
  position: absolute;
  left: 35px;
  top: 337px;
  background-image: url("/src/assets/icon_success_green.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(137, 221, 227, 0.5);
  width: 64px;
  height: 64px;
}

.circle-still-3-confirm {
  position: absolute;
  left: 35px;
  top: 337px;
  background-image: url("/src/assets/icon_still.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(208, 211, 215, 0.5);
  width: 64px;
  height: 64px;
}

.circle-progress-3-confirm {
  position: absolute;
  left: 35px;
  top: 337px;
  background-image: url("/src/assets/icon_progress.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  background-color: rgba(185, 220, 241, 0.5);
  width: 64px;
  height: 64px;
}

@media (max-width: 1250px) {
  .status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3f5f7;
    height: 100%;
    padding-bottom: 50px;
  }

  .line-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 8.5px auto;
  }

  .vertical-line-1,
  .vertical-line-active-2 {
    width: 2px;
    height: 50px;
    background-color: rgba(137, 221, 227, 0.5);
  }

  .vertical-line-inactive-2 {
    width: 2px;
    height: 50px;
    background-color: rgba(208, 211, 215, 0.5);
  }

  /* Vertical Bar*/
  .status-wrapper-confirm {
    position: relative;
    margin: 0 auto;
  }
}

@media (max-width: 540px) {
  .status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3f5f7;
    height: 100%;
    padding: 33px 0px;
  }

  .box-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .line-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 8.5px auto;
  }

  .box {
    width: 160px;
    margin: 0 0 0 32px;
  }

  .box-inner-title {
    font-size: 16px;
    line-height: 22px;
  }

  .box-inner-details-still {
    font-size: 14px;
    line-height: 19px;
  }

  .box-inner-details-done {
    font-size: 14px;
    line-height: 19px;
  }

  .circle-still,
  .circle-progress,
  .circle-done {
    background-size: 22px;
    width: 45px;
    height: 45px;
  }

  .box-inner-button {
    width: 105px;
    height: 33px;
    font-size: 16px;
    line-height: 30px;
    margin-top: 16px;
  }

  .vertical-line-1,
  .vertical-line-active-2 {
    width: 2px;
    height: 50px;
    background-color: rgba(137, 221, 227, 0.5);
  }

  .vertical-line-inactive-2 {
    width: 2px;
    height: 50px;
    background-color: rgba(208, 211, 215, 0.5);
  }

  /* Vertical Bar*/
  .status-wrapper-confirm {
    position: relative;
    margin: 0 auto;
    width: 343px;
    height: 317px;
  }

  .box-1-confirm {
    position: absolute;
    width: 240px;
    height: 93px;
    left: 120px;
    top: 0px;
  }
  .box-2-confirm {
    position: absolute;
    width: 240px;
    height: 93px;
    left: 120px;
    top: 105px;
  }
  .box-3-confirm {
    position: absolute;
    width: 240px;
    height: 93px;
    left: 120px;
    top: 212px;
  }

  .box-inner-title-confirm {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #3b3c3d;
  }

  .box-inner-details-done-confirm {
    position: absolute;
    top: 30px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #3fc1c0;
  }

  .box-inner-details-still-confirm {
    position: absolute;
    top: 30px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #b1b5ba;
  }
  .circle-done-1-confirm {
    position: absolute;
    left: 30px;
    top: 5px;
    background-image: url("/src/assets/icon_success_green.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(137, 221, 227, 0.5);
    width: 45px;
    height: 45px;
  }

  .vertical-line-1-confirm {
    position: absolute;
    left: 50px;
    top: 55px;
    width: 2px;
    height: 50px;
    background-color: rgba(137, 221, 227, 0.5);
  }

  .circle-done-2-confirm {
    position: absolute;
    left: 30px;
    top: 110px;
    background-image: url("/src/assets/icon_success_green.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(137, 221, 227, 0.5);
    width: 45px;
    height: 45px;
  }
  .circle-still-2-confirm {
    position: absolute;
    left: 30px;
    top: 110px;
    background-image: url("/src/assets/icon_still.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(208, 211, 215, 0.5);
    width: 45px;
    height: 45px;
  }

  .circle-progress-2-confirm {
    position: absolute;
    left: 30px;
    top: 110px;
    background-image: url("/src/assets/icon_progress.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(185, 220, 241, 0.5);
    width: 45px;
    height: 45px;
  }

  .box-inner-button-confirm {
    display: block;
    width: 105px;
    height: 33px;
    background: #1b98e3;
    border-radius: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    margin-top: 15px;
  }

  .vertical-line-inactive-2-confirm {
    position: absolute;
    left: 50px;
    top: 160px;
    width: 2px;
    height: 50px;
    background-color: rgba(208, 211, 215, 0.5);
  }

  .vertical-line-active-2-confirm {
    position: absolute;
    left: 50px;
    top: 160px;
    width: 2px;
    height: 50px;
    background-color: rgba(137, 221, 227, 0.5);
  }

  .circle-still-3-confirm {
    position: absolute;
    left: 30px;
    top: 216px;
    background-image: url("/src/assets/icon_still.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(208, 211, 215, 0.5);
    width: 45px;
    height: 45px;
  }
  .circle-progress-3-confirm {
    position: absolute;
    left: 30px;
    top: 216px;
    background-image: url("/src/assets/icon_progress.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(185, 220, 241, 0.5);
    width: 45px;
    height: 45px;
  }
  .circle-done-3-confirm {
    position: absolute;
    left: 30px;
    top: 216px;
    background-image: url("/src/assets/icon_success_green.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    background-color: rgba(137, 221, 227, 0.5);
    width: 45px;
    height: 45px;
  }
}
