.page-wrapper {
  position: relative;
  margin: auto;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
}

.video {
  overflow: hidden;
  display: flex;
  position: relative;
  height: 400px;
  width: 400px;
  padding-left: calc(50% - 200px);
  padding-right: calc(50% - 200px);
}

.iframe {
  height: 100%;
  width: 100%;
}

.banner {
  font-size: 30px;
  padding: 30px 0px;
}

.message {
  padding: 1% calc(50% - 200px);
  font-size: 25px;
  width: 400px;
  max-width: 80%;
}

ul {
  font-size: 20px;
  width: 80%;
  margin-left: 10%;
  padding: 0%;
  text-align: left;
}

.choice {
  background: rgba(0, 120, 200, 0.7);
  width: 200px;
  padding: 10px;
  border-radius: 15px;
  margin: 20px;
  color: white;
  font-size: 20px;
}

.choices {
  display: flex;
  margin-left: calc(50% - 260px);
}

@media screen and (max-width: 500px) {
  .video {
    height: 70vw;
    width: 70vw;
    padding-left: 15vw;
    padding-right: 15vw;
  }

  .banner {
    font-size: 6vw;
    height: 6vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  .message {
    padding: 1vh 10vw 0 10vw;
    font-size: 4.5vw;
    height: 20vh;
  }

  ul {
    font-size: 3vw;
  }

  .choice {
    width: 60vw;
    height: 9.5vw;
    padding: 4vw;
    border-radius: 4vw;
    margin: calc(17vh - 27.75vw) 18vw calc(17vh - 27.75vw) 18vw;
    font-size: 8vw;
  }

  .choices {
    display: inline-block;
    margin: 0;
  }
}
