/*
  PageFooter.css:
  - Description: This is the formatting file of PageFooter.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.page-footer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 15;
}

.footer-logo {
  content: url("/src/assets/sibel_logo_vector.svg");
  width: 107px;
  height: 30px;
  position: relative;
}

.footer-privacy-terms {
  cursor: pointer;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a0a0a0;
  margin-top: 8px;
  margin-bottom: 0px;
}

.footer-details {
  padding: 12px;
  padding-top: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #a0a0a0;
  text-align: center;
  z-index: 1;
  cursor: text;
}

.footer-details:after {
  content: "For help, please contact us at +1-833-324-2092 | Copyright © 2021 Sibel Inc. All rights reserved.";
}

.footerIndex {
  z-index: 10;
}

@media (max-width: 768px) {
  .footer-privacy-terms {
    cursor: pointer;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #a0a0a0;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .page-footer {
    padding-bottom: 6px;
    background-color: transparent;
  }

  .footer-logo {
    width: 71px;
    height: 19px;
  }

  .footer-details {
    padding: 4px;
    padding-top: 2px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .footer-details:after {
    content: "For help, please contact us at +1-833-324-2092 \A Copyright © 2021 Sibel Inc. All rights reserved.";
    white-space: pre-wrap;
  }

  .footerIndex {
    z-index: 10;
  }
}
