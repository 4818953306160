.spots-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.spots-popup-inner {
  position: relative;
  border-radius: 40px;
  padding: 80px 40px;
  width: 90%;
  max-width: 640px;
  background-color: #fff;
  z-index: 20;
}

.spots-popup-inner img {
  margin: 0 calc(50% - 21px);
  width: 42px;
  object-fit: contain;
}

.spots-popup-inner p {
  width: 513px;
  max-width: 90%;
  margin: 35px auto 52px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 22px;
  font-style: normal;
  color: #3b3c3d;
  line-height: 29.96px;
  text-align: center;
}

.spots-popup-inner .x-btn {
  background: url("/src/assets/popup-close.png");
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  padding: 12px;
  position: absolute;
  top: 48px;
  right: 48px;
  cursor: pointer;
}

.spots-popup-inner .spots-close-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.96px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 calc(50% - 213.5px);
  font-family: "Open Sans";
}

.spots-popup-inner .spots-close-btn:hover {
  background: #2188c6;
}

.spots-popup-inner .spots-close-btn:active {
  background: #1b98e3;
}

@media (max-width: 768px) {
  .spots-popup-inner p {
    font-size: 15px;
    margin: auto;
  }

  .spots-popup-inner {
    padding: 40px 10px;
  }

  .spots-popup-inner .spots-close-btn {
    width: 80%;
    margin: 20px 10% 0;
  }
}

@media (max-width: 540px) {
  .spots-popup-inner .spots-close-btn {
    height: 40px;
    background: #1b98e3;
    border-radius: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29.96px;
    color: #ffffff;
    cursor: pointer;
  }
}
