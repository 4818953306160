.popup-email {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.popup-inner-email {
  position: relative;
  padding: 40px;
  width: 100%;
  max-width: 640px;
  height: 450px;
  background: #ffffff;
  align-items: center;
  border-radius: 40px;
}
.email-close {
  position: absolute;

  margin-top: 10px;
  margin-left: 535px;

  height: 24px;
  width: 24px;
  cursor: pointer;
}

.email-input-row {
  position: relative;
  margin-top: 190px;
  width: 475px;
  margin-left: auto;
  margin-right: auto;
}

.email-input {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background-color: #fff;
}

.email-input-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background-color: #fff;
}

.email-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-title {
  position: absolute;
  width: 248px;
  height: 38px;
  left: calc(50% - 248px / 2);
  top: 80px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;

  color: #3b3c3d;
}

.email-wrong-input-message {
  width: 350px;
  position: absolute;
  align-items: center;
  text-align: center;
  margin: 0px auto;
  height: 25px;
  line-height: 22px;
  font-size: 16px;
  left: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
}

.share-details {
  position: absolute;
  width: 478px;
  height: 60px;
  left: calc(50% - 478px / 2);
  top: 134px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: center;

  color: #3b3c3d;
}
.link-btn-email {
  display: block;
  width: 427px;
  height: 53px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 50px;
  margin-left: calc(50% - 427px / 2 + 0.5px);
  cursor: pointer;
}

.link-btn-email:hover {
  background: #2188c6;
}
.link-btn-email:disabled {
  opacity: 0.35;
  cursor: auto;
}

@media (max-width: 540px) {
  .email-img {
    width: 70px;
    margin: 0;
  }

  span.email-img {
    width: 70px;
  }

  .email-img {
    width: 70px;
  }
  .email-wrong-input-message {
    width: 350px;
    position: absolute;
    align-items: center;
    text-align: center;
    margin-top: 150px;
    height: 25px;
    line-height: 22px;
    font-size: 14px;
    left: -50px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
  }
  .popup-inner-email {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: 340px;
    height: 276px;
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }

  .email-close {
    position: absolute;
    top: 15px;
    right: 25px;
    height: 20px;
    width: 20px;
  }

  .share-title {
    position: absolute;
    width: 195px;
    height: 38px;
    left: calc(50% - 195px / 2);
    top: 50px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;

    color: #3b3c3d;
  }

  .share-details {
    position: absolute;
    width: 298px;
    height: 60px;
    left: calc(50% - 298px / 2);
    top: 95px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #3b3c3d;
  }

  .email-input-row {
    position: absolute;
    margin-top: 110px;
    width: 274px;
    margin-left: -7px;
  }
  .email-input {
    display: block;
    width: 274px;
    height: 40px;
    text-indent: 58px;
    outline: none;
    border: 1px solid #1b98e3;
    border-radius: 26.5px;
    background-color: #fff;
  }

  .email-input-failed {
    display: block;
    width: 274px;
    height: 40px;
    text-indent: 58px;
    outline: none;
    border: 1px solid #de4038;
    border-radius: 26.5px;
    background-color: #fff;
  }
  .link-btn-email {
    display: block;
    width: 274px;
    height: 40px;
    background: #1b98e3;
    border-radius: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-top: 175px;
    margin-left: calc(50% - 274px / 2 + 0.5px);
  }
}
