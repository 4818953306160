@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.login-main {
  width: 100%;
  min-height: calc(100vh - 97px);
  display: flex;
  flex-direction: column;
}

.page-login {
  position: relative;
  top: 97px;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.page-login::before {
  content: "";
  position: absolute;
  background-image: url("/src/assets/login-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login-form {
  z-index: 1;
  width: 560px;
  max-width: 560px;
  max-height: 576px;
  margin: 80px auto 292px auto;
  background: linear-gradient(
    95.18deg,
    rgba(255, 255, 255, 0.9) 29.54%,
    rgba(255, 255, 255, 0.2) 181.08%
  );
  border: 0px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  border-radius: 40px;
  padding: 20px 10px;
}

.login-form form {
  width: 80%;
  margin: 0 57px;
}

.login-form h2 {
  margin: 52px 0 40px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #1b98e3;
}

.login-input-row {
  position: relative;
  margin-bottom: 32px;
}
#second-box {
  margin-bottom: 8px;
}

.login-input {
  display: block;
  font-size: 18px;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background: transparent;
}

.login-input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
}

.login-input-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background: transparent;
}

.login-wrong-input-message {
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: 25px;
  line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
}

.input-img-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.input-img-login {
  width: 88px;
}

.input-validate {
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.input-validate {
  width: 74px;
}

.form-login-btn {
  display: block;
  width: 100%;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 26px;
  cursor: pointer;
}

.form-login-btn:not(:disabled):hover {
  background: #2188c6;
}

.form-login-btn:not(:disabled):active {
  background: #1b98e3;
}

.form-login-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.login-forget {
  width: 200px;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 12px 0 68px 0;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .login-wrong-input-message {
    align-items: center;
    text-align: center;
    margin: 0 auto;
    height: 25px;
    line-height: 22px;
    font-size: 12px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
  }
  .login-main {
    min-height: calc(100vh - 56px);
  }

  .page-login {
    top: 56px;
  }

  .page-login::before {
    background-image: url("/src/assets/login-img-mobile.png");
    background-position: center;
  }

  .login-form {
    width: 329px;
    max-width: 329px;
    max-height: 399px;
    padding: 20px 27px;
    margin: 80px auto 277px auto;
  }

  .login-form form {
    width: 100%;
    margin: 28px auto;
  }

  .login-form h2 {
    font-size: 22px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 32px;
  }

  .login-input-row {
    margin-bottom: 16px;
  }

  .login-input {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
    padding: 8px 0px;
  }

  .login-input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
  }

  .login-input-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
    padding: 8px 0px;
  }

  .input-img-login {
    width: 70px;
  }

  span.input-img-login {
    width: 70px;
  }

  .input-validate {
    width: 52px;
  }

  span.input-validate {
    width: 52px;
  }

  .input-validate img {
    width: 20px;
    height: 20px;
  }

  .form-login-btn {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 32px;
    padding: 5px 0;
  }

  .login-forget {
    width: 150px;
    font-size: 16px;
    line-height: 22px;
    margin-top: -4px;
    display: block;
    margin-right: auto;
    margin-right: auto;
  }

  .login-footer {
    margin-top: -10px;
  }
}

@media (max-width: 411px) {
  .login-main {
    min-height: calc(100vh - 56px - 150px);
  }

  .page-login {
    top: 56px;
  }

  .page-login::before {
    background-image: url("/src/assets/login-img-mobile.png");
    background-position: top;
  }

  .login-form {
    width: 329px;
    max-width: 329px;
    max-height: 399px;
    padding: 20px 27px;
    margin: 80px auto 277px auto;
  }

  .login-form form {
    width: 100%;
    margin: 28px auto;
  }

  .login-form h2 {
    font-size: 22px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 32px;
  }

  .login-input-row {
    margin-bottom: 16px;
  }
  #email-icon {
    width: 17px;
  }

  #password-icon {
    width: 20px;
  }
  .login-input {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
    padding: 8px 0px;
  }

  .login-input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
  }

  .login-input-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
    padding: 8px 0px;
  }

  .input-img-login {
    width: 70px;
  }

  span.input-img-login {
    width: 70px;
  }

  .input-validate {
    width: 52px;
  }

  span.input-validate {
    width: 52px;
  }

  .input-validate img {
    width: 20px;
    height: 20px;
  }

  .form-login-btn {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 32px;
    padding: 5px 0;
  }

  .login-forget {
    width: 150px;
    font-size: 16px;
    line-height: 22px;
    margin-top: -4px;
    display: block;
    margin-right: auto;
    margin-right: auto;
  }

  .login-footer {
    margin-top: -10px;
  }
}

@media (max-width: 359px) {
  .page-login {
    top: 56px;
  }

  .page-login::before {
    background-image: url("/src/assets/login-img-mobile.png");
  }

  .login-form {
    padding: 20px 12px;
    width: 80%;
    min-width: 250px;
  }

  .login-form form {
    width: 90%;
    margin: 28px auto;
  }

  .login-form h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  .login-input-row {
    margin-bottom: 16px;
  }

  .login-input {
    font-size: 12px;
    text-indent: 48px;
  }

  .login-input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
  }

  .login-input-failed {
    font-size: 12px;
    text-indent: 48px;
  }

  .login-input-failed::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 22px;
  }

  span.input-img-login {
    width: 48px;
  }

  .input-validate img {
    width: 20px;
    height: 20px;
  }

  .form-login-btn {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 32px;
    padding: 5px 0;
  }

  .login-forget {
    width: 150px;
    font-size: 16px;
    line-height: 22px;
    margin-top: -4px;
    display: block;
    margin-right: auto;
    margin-right: auto;
  }
}

/* challengeName == SMS_MFA */
.main-sms-mfa {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.horizontal-line {
  margin: auto;
  width: 100%;
  max-width: 1042px;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-color: rgba(137, 221, 227, 0.6);
}

.sms-mfa-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1000px;
  height: 564px;
  margin: auto;
}

.sms-mfa-image {
  max-width: 29vw;
  width: 50%;
  margin: auto;
}

.sms-mfa-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.sms-mfa-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  margin-bottom: 28px;
}

.sms-mfa-form h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #3b3c3d;
  line-height: 30px;
  max-width: 506px;
  margin-bottom: 18px;
}

.sms-mfa-code {
  width: 462px;
  margin: 0 0 0 -10px;
}

.sms-mfa-code input {
  width: 57px;
  height: 71px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 11px;
  margin: 0 10px;
  font-size: 36px;
  font-weight: 600;
  line-height: 49.03px;
}

.sms-mfa-code p {
  color: #de4038;
  margin: 0 0 0 10px;
  height: 0px;
  font-size: 18px;
  line-height: 25px;
}

.error-message input {
  border: 1px solid #de4038;
}

.sms-mfa-verify-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29.96px;
  color: #ffffff;
  cursor: pointer;
  margin: 57px 0 18px 0;
}

.sms-mfa-resend-btn {
  background: none;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  text-align: center;
  cursor: pointer;
  width: 188px;
  display: block;
  margin-left: auto;
  margin-right: 154px;
}

.sms-mfa-verify-btn:not(:disabled):hover {
  background: #2188c6;
}

.sms-mfa-verify-btn:not(:disabled):active {
  background: #1b98e3;
}

.sms-mfa-verify-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

@media (max-width: 956px) {
  .main-sms-mfa {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .sms-mfa-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .sms-mfa-image {
    width: 50%;
    max-width: none;
    margin: 0 25%;
  }

  .sms-mfa-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sms-mfa-code {
    width: 462px;
    margin: 0 auto;
    text-align: center;
  }

  .sms-mfa-code p {
    text-align: left;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 540px) {
  .main-sms-mfa {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .sms-mfa-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .sms-mfa-image {
    width: 60%;
    margin: 0 20%;
  }

  .sms-mfa-form h1 {
    font-size: 30px;
    margin: 0 0 12px 0;
  }

  .sms-mfa-form h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    margin: 0 0 12px;
    color: #3b3c3d;
    padding: 0 7%;
  }

  .sms-mfa-code {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .sms-mfa-code input {
    width: 42px;
    margin: 0 5px;
    height: 52px;
    margin: 0 4px;
    font-size: 30px;
    padding: 0;
  }

  .sms-mfa-verify-btn {
    width: 100%;
    height: 40px;
    margin: 32px auto 24px auto;
    font-size: 16px;
  }

  .sms-mfa-resend-btn {
    font-size: 16px;
    width: 100%;
  }

  .sms-mfa-code p {
    font-size: 12px;
    margin: 0;
    text-align: left;
  }

  .sms-mfa-footer .page-footer {
    background-color: transparent;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 380px) {
  .sms-mfa-code input {
    width: 32px;
    margin: 0 5px;
    height: 42px;
    margin: 0 4px;
    font-size: 30px;
    padding: 0;
  }
}
