.dashboard-main {
  width: 100%;
  min-height: calc(100vh - 97px - 150px);
}

.dashboard-banner {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 97px;
  display: flex;
  flex-direction: column;
}

.dashboard-gradient {
  width: 100%;
  height: 307px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 56.25%,
    rgba(255, 255, 255, 0.95) 100%
  );
  transform: rotate(-180deg);
  position: relative;
  z-index: 1;
}

/* Banner */
.dashboard-banner-box {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1440px;
  margin: auto;
  flex-wrap: wrap;
  margin-top: -307px;
}

.banner-img {
  position: absolute;
  background-image: url("/src/assets/banner-dashboard-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  width: 100%;
  height: 637px;
  opacity: 0.7;
  z-index: -3;
}

.dashboard-banner-item {
  flex: 0 0 50%;
  height: 457px;
  text-align: center;
}

.dashboard-description-box {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dashboard-banner-info {
  display: flex;
  margin: 0 auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.dashboard-banner-title-enroll {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: #3e4954;
  width: 521px;
  align-self: center;
  text-align: left;
}

.dashboard-banner-title {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  color: #3e4954;
  width: 620px;
  align-self: center;
  text-align: left;
}

.dashboard-banner-text {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: #3e4954;
  margin-top: 48px;
  width: 521px;
  text-align: left;
}

.dashboard-banner-text-report {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: #3e4954;
  margin-top: 48px;
  width: 620px;
  text-align: left;
}

.list-report {
  list-style-type: none;
  margin: 0;
}

.download-dashboard-btn {
  font-family: "Open Sans";
  /* display: block; */
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 64px;
  padding: 0;
  cursor: pointer;
}

.download-dashboard-btn:hover {
  background: #2188c6;
}

.download-dashboard-btn:active {
  background: #1b98e3;
}

.banner-share-btn {
  font-family: "Open Sans";
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  border: none;
  background: none;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.banner-share-btn:hover {
  color: #2188c6;
}

.banner-share-btn:active {
  color: #1b98e3;
}

.banner-share-btn-disabled {
  font-family: "Open Sans";
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  border: none;
  background: none;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  color: #3fc1c0;
}

.banner-share-btn-disabled:hover {
  color: #4fd1d0;
}

/* Stepper */
.dashboard-stepper {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center;
}

/* Dashboard Content */
.dashboard-container {
  width: 95%;
  height: 100%;
  max-width: 1440px;
  background: #f3f5f7;
  display: flex;
  flex-direction: row;
  margin: auto;
}

/* Card Start */
.dashboard-row {
  display: flex;
  flex-basis: 55%;
  justify-content: center;
}

.dashboard-card-box {
  min-width: 722px;
  height: 424px;
  background: linear-gradient(
    99.88deg,
    rgba(255, 255, 255, 0.9) 8.07%,
    rgba(255, 255, 255, 0.2) 126.39%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 40px;
  margin-top: 52px;
  padding-left: 72px;
}

.dashboard-card-profile {
  width: 100%;
  height: 35px;
  margin: 40px 0 65px 0;
  display: flex;
  flex-direction: row;
}

.dashboard-card-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 55px;
  margin-left: 12px;
  color: #c5c5c5;
}

.dashboard-card-data {
  display: flex;
  width: 100%;
  margin: 30px 0;
}

.card-first-item {
  flex: 0 0 50%;
  width: 50%;
}

.dashboard-date {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #3b3c3d;
}

.dashboard-date-val {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 25px;
  color: #3b3c3d;
  margin-top: 5px;
  margin-bottom: 20px;
}

.card-second-item {
  flex: 0 0 50%;
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.dashboard-icon-container {
  display: flex;
  flex-direction: row;
}

.dashboard-card-email-img {
  margin: 4px 9.24px 0 0;
  width: 17.5px;
  height: 13.5px;
}

.dashboard-card-phone-img {
  margin: 1px 8px 0 0;
  width: 20px;
  height: 19.5px;
}

.dashboard-mail,
.dashboard-num {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #3b3c3d;
  margin-top: 0px;
}

.dashboard-card-address-img {
  margin: 1px 10.29px 0 0;
  width: 15.7px;
  height: 20px;
}

.dashboard-des {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #3b3c3d;
  margin-bottom: 30px;
  margin-top: 0px;
}

.dashboard-change-password {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  cursor: pointer;
}

/* Care Option */
.dashboard-bottom-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f5f7;
  flex-basis: 45%;
  margin-top: 85px;
  margin-bottom: 90px;
  align-items: center;
}

.dashboard-bottom-img-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-bottom-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-bottom-img img {
  width: 150px;
  height: 108.42;
}

.dashboard-bottom-info {
  text-align: center;
  width: 480px;
  margin: auto;
}

.dashboard-bottom-title {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  color: #3e4954;
  margin-top: 36px;
}

.dashboard-bottom-title-still {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  color: #3e4954;
  margin-top: 65px;
}

.dashboard-bottom-btn {
  font-family: "Open Sans";
  width: 427px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  background: #1b98e3;
  border-radius: 40px;
  margin-top: 45px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dashboard-bottom-btn:hover {
  background: #2188c6;
}

.dashboard-bottom-btn:active {
  background: #1b98e3;
}

table {
  width: 335px;
}

.dashboard-times {
  width: 145px;
}

.dashboard-study-coordinator-title {
  font-family: Open Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3C3D;
}

.dashboard-study-coordinator-dates {
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3C3D;
}

td, 
.dashboard-study-coordinator-hours {
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3C3D;
}

/* Footer */
.dashboard-footer {
  background: #f3f5f7;
  width: 100%;
}

@media (max-width: 1250px) {
  .dashboard-banner-title {
    align-items: center;
    text-align: center;
    font-size: 36px;
    line-height: 50px;
  }

  .dashboard-banner-text-report {
    align-items: center;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }

  .dashboard-banner-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .dashboard-stepper {
    flex: 0 0 100%;
    width: 100%;
  }
  .dashboard-card {
    flex: 0 0 100%;
    width: 100%;
  }
  .dashboard-bottom-img {
    flex: 0 0 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .dashboard-bottom-img img {
    margin-left: 5%;
  }

  .dashboard-bottom-info {
    flex: 0 0 100%;
    width: 100%;
  }

  .dashboard-bottom-container {
    margin-top: 52px;
  }

  #firstBox {
    display: none;
  }
  .dashboard-card-box {
    margin-left: auto;
    margin-right: auto;
  }

  .banner-img {
    object-fit: cover;
    opacity: 1;
  }

  .dashboard-description-box {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .dashboard-container {
    width: 100%;
    height: 100%;
    background: #f3f5f7;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .dashboard-banner {
    margin-top: 56px;
  }

  .dashboard-banner-title {
    width: 450px;
    font-size: 28px;
    line-height: 44px;
  }

  .dashboard-banner-text-report {
    width: 450px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }

  .list-report {
    list-style-type: none;
    margin-top: -30px;
  }

  .banner-img {
    width: 100% !important;
    height: 457px !important;
    object-fit: cover;
    opacity: 1;
    background-image: url("/src/assets/dashboard-banner-phone.png");
  }

  .dashboard-banner-item {
    flex: 0 0 100%;
    width: 100%;
  }

  .dashboard-description-box {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .dashboard-stepper {
    flex: 0 0 100%;
    width: 100%;
  }

  .dashboard-card-box {
    min-width: 96vw;
    margin-left: auto;
    margin-right: auto;
    padding: 0 25px;
    border-radius: 19.5px;
  }

  .card-first-item {
    margin: 0 auto;
    padding-right: 40px;
  }

  .card-second-item {
    margin: 0 auto;
    overflow: hidden;
  }

  .dashboard-bottom-info {
    text-align: center;
    padding: 20px;
  }
}

@media (max-width: 540px) {
  .dashboard-main {
    overflow: hidden;
  }

  .dashboard-banner-box {
    overflow: hidden;
  }

  .banner-img {
    width: 100% !important;
    height: 270px !important;
    object-fit: cover;
    background-image: url("/src/assets/dashboard-banner-phone.png");
  }

  #firstBox {
    display: none;
  }

  .dashboard-banner-item {
    height: 270px;
    width: 100%;
  }

  .dashboard-banner-title {
    font-size: 22px;
    line-height: 30px;
    width: 186px;
    align-self: center;
  }

  .dashboard-banner-title-enroll {
    font-size: 30px;
    line-height: 41px;
    width: 186px;
    align-self: center;
  }

  .dashboard-banner-text {
    font-size: 16px;
    line-height: 22px;
    width: 291px;
    margin-top: 24px;
  }

  .dashboard-banner-text-report {
    font-size: 16px;
    line-height: 22px;
    width: 291px;
    margin-top: 24px;
  }

  .download-dashboard-btn {
    width: 247px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 50px auto 0 auto;
  }

  .banner-share-btn {
    font-size: 14px;
    line-height: 19px;
    margin-top: 15px;
  }

  .dashboard-stepper {
    flex: 0 0 100%;
  }

  .dashboard-row {
    padding-top: 30px;
  }

  .dashboard-card {
    height: 280px;
  }

  .dashboard-card-profile {
    width: 100%;
    height: 35px;
    margin: 0px 0 20px 0;
  }

  .dashboard-card-name {
    padding-left: 8%;
    font-size: 16px;
    line-height: 55px;
  }
  .dashboard-activation-code {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #3b3c3d;
  }

  .dashboard-date-val {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #3b3c3d;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .dashboard-date {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #3b3c3d;
  }

  .dashboard-mail {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #3b3c3d;
    margin-top: 0px;
  }

  .dashboard-num {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    color: #3b3c3d;
  }

  .dashboard-des {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #3b3c3d;
    margin-bottom: 20px;
    margin-right: 100px;
    margin-top: 0px;
  }

  .dashboard-row {
    padding: 0px;
  }

  .dashboard-card-box {
    /* min-width: 300px; */
    width: 90vw;
    height: 240px;
    margin-top: 20px;
    padding-left: 25px;
    border-radius: 19.5px;
  }

  .dashboard-card-profile {
    height: 17px;
    margin: 24px 0 31px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dashboard-card-profile img {
    height: 17px;
    width: 17px;
  }

  .dashboard-card-name {
    font-size: 12px;
    line-height: 16px;
    margin-left: 9px;
    padding: 0;
  }

  .card-first-item {
    margin: 0 auto;
    padding-right: 30px;
  }

  .dashboard-date {
    font-size: 12px;
    line-height: 16px;
  }

  .dashboard-date-val {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .card-second-item {
    margin: 0 auto;
    overflow: hidden;
  }

  .dashboard-card-email-img {
    margin: 4px 4.75px 0 0;
    width: 10.5px;
    height: 8.255px;
  }

  .dashboard-card-phone-img {
    margin: 1px 4px 0 0;
    width: 12px;
    height: 11.7px;
  }

  .dashboard-mail,
  .dashboard-num {
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }

  .dashboard-card-address-img {
    margin: 1px 5.37px 0 0;
    width: 9.43px;
    height: 12px;
  }

  .dashboard-des {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .dashboard-change-password {
    font-size: 14px;
    line-height: 19px;
  }

  .dashboard-bottom-container {
    margin-top: 15px;
    margin-bottom: 60px;
  }

  .dashboard-bottom-img {
    margin-bottom: 0px;
  }

  .dashboard-bottom-img img {
    width: 191.14px;
    height: 130.13px;
  }

  .dashboard-bottom-title,
  .dashboard-bottom-title-still {
    font-size: 30px;
    line-height: 41px;
    margin-top: 5px;
    width: 300px;
  }

  .dashboard-bottom-btn {
    width: 274px;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    margin-top: 24px;
  }

  table {
    width: 240px;
  }

  .dashboard-bottom-img-td {
    display: none;
  }
  
  .dashboard-study-coordinator-title {
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B3C3D;
  }
  
  .dashboard-study-coordinator-dates {
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B3C3D;
  }
  
  td, 
  .dashboard-study-coordinator-hours {
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #3B3C3D;
  }
}
