@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

input {
  font-size: 18px;
  line-height: 25px;
}

input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #d0d3d7;
}

.password-main {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.horizontal-line {
  margin: auto;
  width: 100%;
  max-width: 1042px;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-color: rgba(137, 221, 227, 0.6);
}

.password-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1000px;
  height: 564px;
  margin: auto;
}

.password-img {
  max-width: 29vw;
  width: 50%;
  margin: auto;
}

.password-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.password-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 32px;
}

.change-input-row {
  width: 427px;
  position: relative;
  margin-bottom: 24px;
}

.change-input-row-last {
  width: 427px;
  position: relative;
  margin-bottom: 25px;
}

.to-reset-btn {
  background: none;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  text-align: center;
  width: 200px;
  cursor: pointer;
  display: block;
  margin-left: 113px;
  margin-right: 188px;
}

.change-input-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-input-field-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background-color: #f3f5f6;
}

.change-input-field {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background-color: #f3f5f6;
}

.change-input-validate {
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changed-btn {
  font-family: "Open Sans";
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 0 18px 0;
}

.changed-btn:not(:disabled):hover {
  background: #2188c6;
}

.changed-btn:not(:disabled):active {
  background: #1b98e3;
}

.changed-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.MuiTooltip-tooltip {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  border-radius: 10px !important;
  padding: 19px 13px 19px 13px !important;
}

.change-input-row div span {
  transform: translate(38px) !important;
}

.MuiTooltip-arrow::before {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  margin-top: -1px !important;
}

.change-condition-row {
  position: relative;
  width: 100% !important;
  vertical-align: center;
  height: 25px;
}
.change-condition-img-holder {
  width: 13px;
  margin-top: auto;
  margin-right: 8px;
  top: 4.5px;
}
.change-condition-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  left: 35px;
}

.errorMessage-pass {
  position: relative;
  height: 25px;
  margin-top: -25px;
  margin-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
  font-size: 16px;
  line-height: 22px;
}

.errorMessage-pass-main {
  position: relative;
  height: 25px;
  margin-top: -22px;
  margin-left: 20px;
  margin-bottom: 3px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
  font-size: 18px;
  line-height: 25px;
}

@media (max-width: 956px) {
  .errorMessage-pass {
    position: relative;
    margin-top: -25px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 16px;
    text-align: center;
    align-items: center;
    line-height: 22px;
  }
  .errorMessage-pass-main {
    position: relative;
    height: 25px;
    margin-top: -22px;
    margin-left: unset;
    margin-bottom: 3px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 18px;
    line-height: 25px;
  }
  .password-main {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .password-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .password-img {
    width: 50%;
    max-width: none;
    margin: 0 25%;
  }

  .password-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .horizontal-line {
    display: none;
  }

  .to-reset-btn {
    width: 200px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  /*Tooltip CSS*/
  .pass1-tip-custom {
    width: 200px !important;
    background-color: #3e4954 !important;
    opacity: 1 !important;
    border-radius: 10px !important;
    box-shadow: 0px 10.2736px 31.9623px 9.13208px rgba(0, 0, 0, 0.05),
      0px 3.42453px 6.84906px -4.56604px rgba(0, 0, 0, 0.12) !important;
    filter: drop-shadow(0px 6.84906px 18.2642px rgba(0, 0, 0, 0.08)) !important;
    padding: 0px !important;
    padding-top: 5px !important ;
    padding-bottom: 5px !important ;
  }
  .__react_component_tooltip.place-top:after {
    left: 23px !important;
  }
  .condition-row {
    position: relative;
    width: 100% !important;
    vertical-align: center;
    height: 20px;
  }
  .condition-img-holder {
    position: absolute;
    width: 10px;
    margin-top: auto;
    margin-left: 13px;
    top: 3.5px;
  }
  .condition-text {
    position: absolute;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #ffffff;
    left: 30px;
  }
}

@media (max-width: 540px) {
  .errorMessage-pass {
    position: relative;
    margin-top: -14px;
    margin-bottom: -6px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 12px;
    text-align: center;
    align-items: center;
    line-height: 18px;
  }
  .errorMessage-pass-main {
    position: relative;
    height: 25px;
    margin-top: -34px;
    margin-left: unset;
    margin-bottom: 1px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 14px;
    line-height: 22px;
  }
  .password-main {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .password-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .password-form h1 {
    font-size: 30px;
    margin: 0 0 24px 0;
  }

  .password-img {
    width: 60%;
    margin: 0 20%;
  }

  .change-input-row {
    width: 73%;
    margin-bottom: 12px;
  }

  .change-input-row-last {
    width: 73%;
    margin-bottom: 32px;
  }

  .changed-btn {
    width: 73%;
    margin: 0 15% 24px 15%;
    font-size: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  .to-reset-btn {
    background: none;
    border: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #1b98e3;
    text-align: center;
    width: 150px;
    display: block;
    margin: 0 auto;
  }

  .change-input-field-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  .change-input-field {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  input {
    font-size: 16px;
    line-height: 22px;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: #d0d3d7;
  }

  .change-input-img {
    width: 70px;
  }

  .change-input-validate {
    width: 52px;
  }

  .change-input-img img,
  .change-input-validate img {
    width: 20px;
    height: 20px;
  }

  .change-input-row div span {
    transform: translate(30px) !important;
  }
}

@media (max-width: 359px) {
  /*Tooltip CSS*/
  .pass1-tip-custom {
    width: 160px !important;
    background-color: #3e4954 !important;
    opacity: 1 !important;
    border-radius: 10px !important;
    box-shadow: 0px 10.2736px 31.9623px 9.13208px rgba(0, 0, 0, 0.05),
      0px 3.42453px 6.84906px -4.56604px rgba(0, 0, 0, 0.12) !important;
    filter: drop-shadow(0px 6.84906px 18.2642px rgba(0, 0, 0, 0.08)) !important;
    padding: 0px !important;
    padding-top: 3px !important ;
    padding-bottom: 3px !important ;
  }
  .__react_component_tooltip.place-top:after {
    left: 17px !important;
  }
  .condition-row {
    position: relative;
    width: 100% !important;
    vertical-align: center;
    height: 18px;
  }
  .condition-img-holder {
    position: absolute;
    width: 10px;
    margin-top: auto;
    margin-left: 10px;
    top: 3.5px;
  }
  .condition-text {
    position: absolute;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 18px;
    color: #ffffff;
    left: 25px;
  }
}
