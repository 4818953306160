/**confirm-input mode**/
input {
  font-size: 18px;
  line-height: 25px;
}

input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #d0d3d7;
}

.main-reset-password {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.horizontal-line {
  margin: auto;
  width: 100%;
  max-width: 1042px;
  justify-content: center;
  align-items: center;
  height: 1px;
  background-color: rgba(137, 221, 227, 0.6);
}

.main-reset-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1000px;
  height: 564px;
  margin: auto;
}

.reset-password-img {
  max-width: 29vw;
  width: 50%;
  margin: auto;
}

.reset-password-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.reset-password-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}

.reset-input-row {
  width: 427px;
  position: relative;
  margin-bottom: 32px;
}
.reset-input-row-last {
  width: 427px;
  position: relative;
  margin-bottom: 32px;
}

.reset-input-field {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background-color: #f3f5f6;
}

.reset-input-field-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background-color: #f3f5f6;
}

.reset-input-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-input-validate {
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-btn {
  font-family: "Open Sans";
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 0 70px 0;
}

.reset-btn:not(:disabled):hover {
  background: #2188c6;
}

.reset-btn:not(:disabled):active {
  background: #1b98e3;
}

.reset-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.MuiTooltip-tooltip {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  border-radius: 10px !important;
  padding: 19px 13px 19px 13px !important;
}

.reset-input-row div span {
  transform: translate(38px) !important;
}

.MuiTooltip-arrow::before {
  background-color: #3e4954 !important;
  border: 1px solid #3e4954 !important;
  margin-top: -1px !important;
}

.reset-condition-row {
  position: relative;
  width: 100% !important;
  vertical-align: center;
  height: 25px;
}
.reset-condition-img-holder {
  width: 13px;
  margin-top: auto;
  margin-right: 8px;
  top: 4.5px;
}
.reset-condition-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  left: 35px;
}

.password-errorMessage-small {
  position: relative;
  height: 25px;
  margin-top: -32px;
  margin-bottom: 15px;
  margin-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
  font-size: 16px;
  line-height: 22px;
}
.password-errorMessage-main {
  height: 25px;
  position: relative;
  margin-top: -36px;
  margin-bottom: 4px;
  margin-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
  font-size: 18px;
  line-height: 25px;
}

@media (max-width: 956px) {
  .password-errorMessage-small {
    position: relative;
    margin-top: -28px;
    margin-bottom: 5px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 16px;
    text-align: center;
    align-items: center;
    line-height: 22px;
  }
  .password-errorMessage-main {
    position: relative;
    margin-top: -32px;
    margin-bottom: 4px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 18px;
    line-height: 25px;
  }
  .main-reset-password {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .main-reset-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .reset-password-img {
    width: 50%;
    max-width: none;
    margin: 0 25%;
  }

  .reset-password-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .horizontal-line {
    display: none;
  }
}
@media (max-width: 540px) {
  .password-errorMessage-small {
    position: relative;
    margin-top: -32px;
    margin-bottom: 8px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 12px;
    text-align: center;
    align-items: center;
    line-height: 20px;
  }
  .password-errorMessage-main {
    position: relative;
    margin-top: -32px;
    margin-bottom: 0px;
    margin-left: unset;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    color: #de4038;
    font-size: 14px;
    line-height: 22px;
  }

  .reset-input-row div span {
    transform: translate(30px) !important;
  }

  .main-reset-password {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .main-reset-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .reset-password-form h1 {
    font-size: 30px;
    margin: 0 0 24px 0;
  }

  .reset-password-img {
    width: 60%;
    margin: 0 20%;
  }

  .reset-input-row {
    width: 73%;
    margin-bottom: 12px;
  }

  .reset-input-row-last {
    width: 73%;
    margin-bottom: 32px;
  }

  .reset-btn {
    width: 73%;
    margin: 0 15% 24px 15%;
    font-size: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  .reset-input-field-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  .reset-input-field {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  input {
    font-size: 16px;
    line-height: 22px;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: #d0d3d7;
  }

  .reset-input-img {
    width: 70px;
    margin: 0;
  }

  span.reset-input-img {
    width: 70px;
  }

  .reset-input-img {
    width: 70px;
  }

  .reset-input-validate {
    width: 52px;
  }

  .reset-input-img img,
  .reset-input-validate img {
    width: 20px;
    height: 20px;
  }
}

/**request reset mode**/
.request-reset-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.request-reset-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  margin-bottom: 32px;
}

.request-reset-form h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  max-width: 430px;
  padding: 0;
  margin: 0;
  margin-bottom: 19px;
  color: #3b3c3d;
}

.request-reset-inputbox {
  position: relative;
  width: 427px;
}

.request-reset-input-row {
  height: 77px;
  margin-bottom: 17px;
}

.request-reset-input {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #1b98e3;
  border-radius: 26.5px;
  background: transparent;
}

.request-reset-input-failed {
  display: block;
  width: 100%;
  height: 53px;
  text-indent: 80px;
  outline: none;
  border: 1px solid #de4038;
  border-radius: 26.5px;
  background: transparent;
}

.input-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-validate {
  position: absolute;
  top: 0;
  right: 0;
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-error-email {
  align-items: center;
  text-align: left;
  margin: 0 0 0 32px;
  line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #de4038;
}

.reset-form-request-reset-btn {
  font-family: "Open Sans";
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  margin: 0;
  margin-bottom: 50px;
}

.reset-form-request-reset-btn:not(:disabled):hover {
  background: #2188c6;
}

.reset-form-request-reset-btn:not(:disabled):active {
  background: #1b98e3;
}

.reset-form-request-reset-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}

.request-reset-footer .page-footer {
  background-color: transparent;
}

@media (max-width: 956px) {
  .request-reset-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 540px) {
  .request-reset-input-row {
    position: relative;
    width: 73%;
  }

  .request-reset-inputbox {
    position: relative;
    width: 100%;
  }

  .request-reset-form h1 {
    font-size: 30px;
    margin: 0 0 12px 0;
  }

  .request-reset-form h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    margin: 0 0 18px;
    padding: 0;
    width: 73%;
  }

  .reset-form-request-reset-btn {
    width: 73%;
    margin: 0 15% 24px 15%;
    font-size: 20px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  .request-reset-input {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  .request-reset-input-failed {
    font-size: 16px;
    height: 40px;
    text-indent: 64px;
    border-radius: 20px;
  }

  input {
    font-size: 16px;
    line-height: 22px;
  }

  input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 22px;
    color: #d0d3d7;
  }

  .input-img {
    width: 70px;
  }

  .input-validate {
    width: 52px;
  }

  .input-img img,
  .input-validate img {
    width: 20px;
    height: 20px;
  }

  .input-error-email {
    font-size: 12px;
  }
}

/**code-input mode**/

.main-reset-code {
  padding: 75px 0 130px 0;
  width: 100%;
  min-height: calc(100vh - 155px - 150px);
  background-color: #f3f5f6;
}

.reset-code-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 1000px;
  height: 564px;
  margin: auto;
}

.reset-code-image {
  max-width: 29vw;
  width: 50%;
  margin: auto;
}

.reset-code-form {
  width: 50%;
  margin: auto;
  margin-left: 50px;
}

.reset-code-form h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  background: #89dde3;
  background: -webkit-linear-gradient(
    89.86deg,
    #89dde3 -24.82%,
    #1b98e3 99.75%
  );
  background: -moz-linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background: linear-gradient(89.86deg, #89dde3 -24.82%, #1b98e3 99.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  margin-bottom: 28px;
}

.reset-code-form h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #3b3c3d;
  font-size: 22px;
  line-height: 30px;
  max-width: 506px;
  margin-bottom: 18px;
}

.reset-code-code {
  width: 462px;
  margin: 0 0 0 -10px;
}

.reset-code-code input {
  width: 57px;
  height: 71px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 11px;
  margin: 0 10px;
  font-size: 36px;
  font-weight: 600;
  line-height: 49.03px;
}

.reset-code-code p {
  color: #de4038;
  margin: 0 0 0 10px;
  height: 0px;
  font-size: 18px;
  line-height: 25px;
}

.error-message input {
  border: 1px solid #de4038;
}

.reset-code-verify-btn {
  width: 427px;
  height: 56px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  margin: 57px 0 18px 0;
  font-family: "Open Sans";
}
.reset-code-resend-btn {
  background: none;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
  text-align: center;
  cursor: pointer;
  width: 188px;
  display: block;
  margin-left: auto;
  margin-right: 154px;
}

.reset-code-verify-btn:not(:disabled):hover {
  background: #2188c6;
}

.reset-code-verify-btn:not(:disabled):active {
  background: #1b98e3;
}
.reset-code-verify-btn:disabled {
  opacity: 0.35;
  cursor: auto;
}
@media (max-width: 956px) {
  .main-reset-code {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .reset-code-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .reset-code-image {
    width: 50%;
    max-width: none;
    margin: 0 25%;
  }

  .reset-code-form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .reset-code-resend-btn {
    margin: auto;
  }

  .reset-code-code {
    width: 462px;
    margin: 0 auto;
    text-align: center;
  }

  .reset-code-code p {
    text-align: left;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 540px) {
  .main-reset-code {
    min-height: calc(100vh - 90px - 64px);
    padding-top: 0;
  }

  .reset-code-container {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .reset-code-image {
    width: 60%;
    margin: 0 20%;
  }

  .reset-code-form h1 {
    font-size: 30px;
    margin: 0 0 12px 0;
  }
  .reset-code-form h3 {
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    margin: 0 0 12px;
    padding: 0 7%;
    color: #3b3c3d;
  }
  .reset-code-code {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  .reset-code-code input {
    width: 42px;
    margin: 0 5px;
    height: 52px;
    margin: 0 4px;
    font-size: 30px;
    padding: 0;
  }

  .reset-code-verify-btn {
    width: 100%;
    height: 40px;
    margin: 32px auto 24px auto;
    font-size: 16px;
  }
  .reset-code-resend-btn {
    font-size: 16px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .reset-code-code p {
    font-size: 12px;
    margin: 0;
    text-align: left;
  }

  .sms-mfa-footer .page-footer {
    background-color: transparent;
  }

  .horizontal-line {
    display: none;
  }
}

@media (max-width: 380px) {
  .reset-code-code input {
    width: 32px;
    margin: 0 5px;
    height: 42px;
    margin: 0 4px;
    font-size: 30px;
    padding: 0;
  }
}
