label {
  min-width: 150px;
  display: inline-block;
  margin: 10px;
}

.title {
  margin: 20px;
  font-size: 20px;
}

.errorMessage {
  color: red;
}

#submit {
  margin: 10px;
}

dropdown {
  height: 10;
}
