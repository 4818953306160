.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-data {
  /* position: relative;
  width: 536px;
  left: calc(50% - 536px / 2 + 1px);
  top: 154px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  overflow-y: scroll;
  color: #3b3c3d; */
  width: 536px;
  max-height: min(calc(100vh - 123px - 57px), 700px);
  margin: 0 auto;
  margin-top: 128px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #3b3c3d;
  text-align: left;
  overflow-y: scroll;
  overflow-x: hidden;
}

.inner-data-content {
  margin: 0 auto;
  margin-top: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.steps {
  font-weight: 800;
  position: relative;
  margin-left: 30px;
  width: 536px;
  padding-right: 30px;
  font-size: 22px;
}

.popup-inner {
  position: relative;
  padding: 40px;
  width: 100%;
  max-width: 640px;
  max-height: 100vh;
  background: #ffffff;
  align-items: center;
  border-radius: 40px;
}
.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.inner-icon {
  position: absolute;
  width: 56px;
  height: 65px;
  left: 292px;
  top: 72px;
}

.link-btn {
  font-family: "Open Sans";
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 427px;
  height: 53px;
  background: #1b98e3;
  border-radius: 40px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 50px;
  cursor: pointer;
}

.link-btn:hover {
  background: #2188c6;
}

@media (max-width: 768px) {
  .popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: 340px;
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }

  .inner-data {
    /* position: relative;
    width: 274px;
    left: calc(50% - 274px / 2 + 1px);
    top: 80px; */
    width: 250px;
    max-height: min(calc(100vh - 123px - 57px), 620px);
    margin: 0 auto;
    margin-top: 80px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #3b3c3d;
  }

  .close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 20px;
  }

  .inner-icon {
    position: absolute;
    width: 33px;
    height: 37px;
    left: calc(50% - 33px / 2);
    top: 50px;
  }

  .link-btn {
    display: block;
    width: 100%;
    height: 40px;
    background: #1b98e3;
    border-radius: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .steps {
    position: relative;
    margin-left: 25px;
    max-width: 240px;
    padding-right: 15px;
    font-size: 12px;
  }
}

@media (max-width: 540px) {
  .popup-inner {
    position: relative;
    padding: 40px;
    width: 100%;
    max-width: 340px;
    background: #ffffff;
    align-items: center;
    border-radius: 40px;
  }

  .inner-data {
    /* position: relative;
    width: 274px;
    left: calc(50% - 274px / 2 + 1px);
    top: 80px; */
    width: 250px;
    max-height: min(calc(100vh - 123px - 57px), 620px);
    margin: 0 auto;
    margin-top: 80px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: left;

    color: #3b3c3d;
  }

  .close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 20px;
  }

  .inner-icon {
    position: absolute;
    width: 33px;
    height: 37px;
    left: calc(50% - 33px / 2);
    top: 50px;
  }

  .link-btn {
    display: block;
    width: 100%;
    height: 40px;
    background: #1b98e3;
    border-radius: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  .steps {
    position: relative;
    margin-left: 25px;
    max-width: 240px;
    padding-right: 15px;
    font-size: 12px;
  }
}

@media (max-width: 360px) {
  .inner-icon {
    position: absolute;
    width: 33px;
    height: 37px;
    left: calc(50% - 33px / 2);
  }
}
