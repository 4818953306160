/* 
  ConfirmUser.css:
  - Description: This is the formatting file of ConfirmUser.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.page-wrapper {
  position: relative;
  margin: auto;
  height: 100vh;
  width: 100vw;
  background: transparent;
}

.submit-button {
  font-size: 1.5em;
  font-weight: 600;
  margin: 10%;
  padding: 3%;
}

@media screen and (max-width: 325px) {
  .page-wrapper {
    height: 700px;
  }
}
