/*
  ConfirmUser.css:
  - Description: This is the formatting file of ConfirmUser.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.page-wrapper {
  width: 100%;
  height: calc(100vh - 155px - 150px);
  min-height: calc(100vh - 155px - 150px);
}

.main-surveyinfo {
  display: flex;
  flex-direction: column;
  padding: 20px 0 32px 0;
  width: 100%;
}

.pre-testing-survey {
  display: flex;
  height: 494px;
  width: 70%;
  max-width: 1040px;
  margin: 0 auto;
}

.qualification-survey,
.consent-quiz,
.consent-form,
.post-testing-survey1,
.post-testing-survey2 {
  display: flex;
  height: 649px;
  width: 70%;
  max-width: 1040px;
  margin: 0 auto 70px auto;
}

.iframe {
  height: 100%;
  width: 100%;
  border-radius: 40px;
  border: none;
}

.survey-pre-trial-message,
.survey-consent-quiz-message {
  width: 704px;
  margin: 10px auto 50px auto;
}

.survey-pre-trial-message::before {
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 30px;
  color: #1b98e3;
  content: "You've been enrolled in the study! Please complete this short pre-study survey before selecting your study date.";
}

.survey-consent-quiz-message::before {
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 30px;
  color: #1b98e3;
  cursor: text;
  content: "Next, you are going to review and sign the consent form. We will have a few questions following the consent form to help you review your understanding of the study.";
}

@media (max-width: 768px) {
  .page-wrapper {
    width: 100%;
    height: calc(100vh - 90px - 108px);
    min-height: calc(100vh - 90px - 108px);
  }

  .main-surveyinfo {
    padding: 20px 0 62px 0;
  }

  .pre-testing-survey,
  .consent-quiz,
  .consent-form {
    width: 85.33%;
    height: 440px;
  }

  .qualification-survey,
  .post-testing-survey1,
  .post-testing-survey2 {
    width: 85.33%;
    height: 520px;
  }

  .survey-pre-trial-message {
    width: 85.33%;
    margin: -10px auto 24px auto;
  }

  .survey-pre-trial-message::before {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
    color: #1b98e3;
    content: "You've been enrolled in the study! Please complete this short pre-study survey before selecting your study date.";
  }

  .survey-consent-quiz-message {
    width: 85.33%;
    margin: -10px auto 24px auto;
  }

  .survey-consent-quiz-message::before {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
    color: #1b98e3;
    content: "You've been enrolled in the study! Please complete this short pre-study survey before selecting your study date.";
  }
}
