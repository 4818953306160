/*
  PageHeader.css:
  - Description: This is the formatting file of PageHeader.js component
*/

@import url("https://fonts.googleapis.com/css?family=Open+Sans");
.page-banner {
  position: relative;
  height: 155px;
  width: 100%;
  background-color: #f3f5f6;
}
.page-banner::after {
  content: "";
  position: absolute;
  height: 155px;
  width: 100%;
  background-image: url("/src/assets/anne-sleep-page-header-scaled.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.app-logo {
  content: url("/src/assets/anne-sleep-logo-scaled.png");
  position: absolute;
  width: 167px;
  height: 83px;
  left: calc(50% - 167px / 2 - 0.5px);
  top: calc(50% - 83px / 2 + 17.5px);
  cursor: pointer;
  z-index: 5;
}

@media screen and (max-width: 500px) {
  .page-banner {
    height: 90px;
  }
  .page-banner::after {
    height: 90px;
    background-image: url("/src/assets/anne-sleep-page-header-mobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .app-logo {
    width: 99.6px;
    height: 37.2px;
    left: calc(50% - 99.6px / 2);
    bottom: 14px;
    top: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .page-banner {
    height: 90px;
  }
  .page-banner::after {
    height: 90px;
    background-image: url("/src/assets/anne-sleep-page-header-mobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .app-logo {
    width: 90px;
    height: 45px;
    left: calc(50% - 90px / 2);
    bottom: 23px;
    top: 23px;
  }
}
